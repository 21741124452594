import { Colors, InputFile, space } from '@workos-inc/ui-kit';
import { Fieldset } from 'components/fieldset';
import { Label } from 'components/label';
import { Error } from 'interfaces/errors';
import React, { ChangeEventHandler, CSSProperties } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ error?: boolean }>`
  margin-top: ${space.spacing(2)};

  & > div {
    border-color: ${(props) =>
      props.error ? 'rgba(208, 61, 61, 0.5)' : Colors.MidGray};
  }
`;

interface FileInputProps {
  accept?: string;
  buttonText?: string;
  error?: Error;
  filename: string;
  id?: string;
  label?: string;
  name: string;
  onSubmit?(): void;
  onUpload?: ChangeEventHandler;
  style?: CSSProperties;
  value?: string;
}

export const FileField: React.FC<Readonly<FileInputProps>> = ({
  accept = '.cer, .cert, .crt, .key, .pem',
  error,
  filename,
  id = '',
  label,
  name,
  onUpload,
  value,
}) => {
  const isError = error && error.value === value;
  return (
    <Fieldset>
      <Label color={isError ? Colors.Red : undefined}>{label}</Label>
      <Container error={isError}>
        <InputFile
          accept={accept}
          content={value}
          error={isError ? error?.message : undefined}
          filename={filename}
          id={id}
          label=""
          name={name}
          onChange={onUpload}
        />
      </Container>
    </Fieldset>
  );
};
