import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const StepPingFederate: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <>
    {activeStep === 1 && (
      <Article>
        <H1>Step 1: Create SAML SP Connection</H1>

        <Img
          priority
          height={1396}
          src="/sso/ping-federate/v1/ping-federate-1.png"
          width={3208}
        />

        <Paragraph>
          Select “Applications” from the top menu of your PingFederate
          Administrator Dashboard, then select “SP Connections”.
        </Paragraph>

        <Img
          priority
          height={988}
          src="/sso/ping-federate/v1/ping-federate-2.png"
          width={2708}
        />

        <Paragraph>
          On the “SP Connections” page, select the “Create Connection” button to
          begin creating a SAML SP Connection.
        </Paragraph>

        <Img
          height={893}
          src="/sso/ping-federate/v1/ping-federate-3.png"
          width={2734}
        />

        <Paragraph>
          On the “Connection Template” page, select “Do not use a template for
          this connection” and click “Next”.
        </Paragraph>

        <Img
          height={1164}
          src="/sso/ping-federate/v1/ping-federate-4.png"
          width={2801}
        />

        <Paragraph>
          On the “Connection Type” page, select “Browser SSO Profiles” with the
          “SAML 2.0” Protocol, and click “Next”.
        </Paragraph>

        <Img
          height={732}
          src="/sso/ping-federate/v1/ping-federate-5.png"
          width={2628}
        />

        <Paragraph>
          On the “Connection Options” page, select only “Browser SSO” and click
          “Next”.
        </Paragraph>

        <Img
          height={802}
          src="/sso/ping-federate/v1/ping-federate-6.png"
          width={2694}
        />

        <Paragraph>
          On the “Import Metadata” page, select “None” and click “Next”.
        </Paragraph>

        <Img
          height={924}
          src="/sso/ping-federate/v1/ping-federate-7-edited.png"
          width={2696}
        />

        <CopyInput
          label="Copy this IdP URI (Entity ID)"
          value={connection?.saml_entity_id}
        />

        <Paragraph>
          On the “General Info” page, paste the IdP URI (Entity ID) into the
          “Partner’s Entity ID (Connection ID)” field, and add a unique name in
          the “Connection Name” field, then click “Next”.
        </Paragraph>

        <Confirm
          label="I’ve configured the Entity ID. Continue to the next step."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 2 && (
      <Article>
        <H1>Step 2: Configure Browser SSO Settings</H1>

        <Img
          priority
          height={798}
          src="/sso/ping-federate/v1/ping-federate-8.png"
          width={2678}
        />

        <Paragraph>
          On the “Browser SSO” page, select the “Configure Browser SSO” button.
        </Paragraph>

        <Img
          priority
          height={854}
          src="/sso/ping-federate/v1/ping-federate-9.png"
          width={2668}
        />

        <Paragraph>
          On the “SAML Profiles” page, select “SP-initiated SSO” under the
          “Single Sign-On (SSO) Profiles”, and then click “Next”.
        </Paragraph>

        <Img
          height={842}
          src="/sso/ping-federate/v1/ping-federate-10.png"
          width={2682}
        />

        <Paragraph>
          Configure the Assertion Lifetime and click “Next”.
        </Paragraph>

        <Confirm
          label="I’ve finished configuring the Browser SSO Settings
          and clicked “Next”."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 3 && (
      <Article>
        <H1>Step 3: Configure Assertion Creation</H1>

        <Img
          priority
          height={1134}
          src="/sso/ping-federate/v1/ping-federate-11.png"
          width={2684}
        />

        <Paragraph>
          On the “Assertion Creation” page, select the “Configure Assertion
          Creation” button.
        </Paragraph>

        <Img
          priority
          height={1194}
          src="/sso/ping-federate/v1/ping-federate-12.png"
          width={2684}
        />

        <Paragraph>
          On the Identity Mapping page, select the “Standard” option and click
          “Next”.
        </Paragraph>

        <ConnectionAttributeMapping connectionType={connection?.type} />

        <Img
          height={1366}
          src="/sso/ping-federate/v1/ping-federate-13.png"
          width={2688}
        />

        <Paragraph>
          On the “Attribute Contract” page, define at least id, email, firstName
          and lastName attributes, as shown above, and then click “Next”.
        </Paragraph>

        <Img
          height={1052}
          src="/sso/ping-federate/v1/ping-federate-14.png"
          width={2688}
        />

        <Paragraph>
          On the “Authentication Source Mapping” page, define the attribute
          mapping for your SAML setup. This can vary based on how you have
          PingFederate configured. Below, we describe an example that user an
          Authentication Policy and user information from an LDAP server.
        </Paragraph>

        <Img
          height={1486}
          src="/sso/ping-federate/v1/ping-federate-15.png"
          width={2702}
        />

        <Paragraph>
          On the “Authentication Policy Mapping” setup, we define the following
          “Attribute Contract Fulfillment” settings to map the attributes to
          content from the policy or the LDAP server.
        </Paragraph>

        <Confirm
          label="I’ve configured the SAML Assertion. Continue to next step."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 4 && (
      <Article>
        <H1>Step 4: Configure Protocol Settings</H1>

        <Img
          priority
          height={1152}
          src="/sso/ping-federate/v1/ping-federate-16.png"
          width={2694}
        />

        <Paragraph>
          Navigate to the “Protocol Settings” page and select the “Configure
          Protocol Settings” button.
        </Paragraph>

        <Img
          priority
          height={932}
          src="/sso/ping-federate/v1/ping-federate-17-edited.png"
          width={2686}
        />

        <CopyInput
          label="Copy this ACS URL / Single Sign-On URL"
          value={connection?.saml_acs_url}
        />

        <Paragraph>
          On the “Assertion Consumer Service URL” page, paste the ACS URL into
          the Endpoint URL field with a POST binding, then click “Next”.
        </Paragraph>

        <Img
          height={932}
          src="/sso/ping-federate/v1/ping-federate-18.png"
          width={2686}
        />

        <Paragraph>
          On the “Allowable SAML Bindings” page, check at least POST and
          REDIRECT, then click “Next”.
        </Paragraph>

        <Img
          height={880}
          src="/sso/ping-federate/v1/ping-federate-19.png"
          width={2686}
        />

        <Paragraph>
          On the “Signature Policy” page, select “Always Sign Assertion”, then
          click “Next”.
        </Paragraph>

        <Img
          height={1532}
          src="/sso/ping-federate/v1/ping-federate-20.png"
          width={2702}
        />

        <Paragraph>
          On the “Encryption Policy” page, select “None”, then click “Next”.
        </Paragraph>

        <Confirm
          label="I’ve configured the SAML Protocol Settings. Continue to next step."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 5 && (
      <Article>
        <H1>Step 5: Configure Credentials</H1>

        <Img
          priority
          height={846}
          src="/sso/ping-federate/v1/ping-federate-21.png"
          width={2690}
        />

        <Paragraph>
          Navigate to the “Credentials” page, and select the “Configure
          Credentials” button.
        </Paragraph>

        <Img
          priority
          height={1064}
          src="/sso/ping-federate/v1/ping-federate-22.png"
          width={2698}
        />

        <Paragraph>
          On the “Digital Signature Settings” page, select a signing certificate
          and the RSA SHA256 signing algorithm, then click “Done”.
        </Paragraph>

        <Confirm
          label="I’ve configured the SAML Credentials. Continue to next step."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 6 && (
      <Article>
        <H1>Step 6: Upload IdP Data</H1>

        <Img
          priority
          height={1121}
          src="/sso/ping-federate/v1/ping-federate-23.png"
          width={2666}
        />

        <Paragraph>
          On the SP Connection list, find your WorkOS SAML 2.0 connection. Click
          on the "Select Action" menu and then select "Export Metadata" to
          download the connection metadata.
        </Paragraph>

        <Img
          priority
          height={802}
          src="/sso/ping-federate/v1/ping-federate-24.png"
          width={2671}
        />

        <Paragraph>
          In the metadata file, you'll see something similar to the following
          lines:
        </Paragraph>

        <Paragraph>
          SingleSignOnService <br />
          Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST"
          <br />
          Location="https://mypingfederate.com/idp/SSO.saml2"
        </Paragraph>

        <Paragraph>
          Copy and paste the Location URL (the URL after "Location=") into the
          Identity Provider Single Sign-On URL field at the end of this page.
        </Paragraph>

        <Paragraph>
          To download the X.509 certificate, navigate to the "Security" top menu
          and go to "Signing & Decryption Keys & Certificates". Select the X.509
          certificate used to sign the SAML Response in the WorkOS SP
          Connection. Click the "Select Action" menu and select "Export". Upload
          this file in the X.509 certificate field below.
        </Paragraph>

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={!!(errors?.saml_idp_url || errors?.saml_x509_certs)}
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="1. Identity Provider Single Sign-On URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://foo-corp.okta.com/app/exkgq2/sso/saml"
              value={connection?.saml_idp_url}
            />

            <FileField
              error={errors?.saml_x509_certs}
              filename="X.509 Certificate"
              label="2. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    )}
  </>
);
