import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { useMemo } from 'react';
import { ulid } from 'ulid';

export const StepDuoSAML: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => {
  const app = appName && appName.toLowerCase().split(' ').join('_');
  const defaultSPEntityID = useMemo(() => `urn:entity:${app}:${ulid()}`, [app]);

  return (
    <>
      {activeStep === 1 && (
        <Article>
          <H1>Step 1: Create an SSO Configuration in Duo</H1>

          <Paragraph>
            The Duo SAML integration is unique in that it requires a 3rd party
            IDP to federate the authentication. This means that along with the
            four pieces of information, you’ll also need to configure a Single
            Sign on Authentication Source and a Cloud Application in your Duo
            Workspace.
          </Paragraph>

          <Paragraph>
            The high level overview of the authentication flow for this Service
            Provider initiated login:
          </Paragraph>

          <Img
            priority
            height={210}
            src="/sso/duo/v1/duo-saml-1.png"
            width={1475}
          />

          <Paragraph>
            WorkOS will allow you to use any Duo supported IDP to handle the
            Federated authentication. Since each IDP will have different ways of
            setting up the SSO connection between Duo and the IDP, please refer
            to the documentation that Duo provides to configure a Duo SSO
            Connection.
          </Paragraph>

          <Paragraph>
            <Link
              newTab
              appearance="highlight"
              aria-label="Link to the Duo SSO App documentation"
              href="https://duo.com/docs/sso#enable-duo-single-sign-on"
            >
              Duo Single Sign-on Application Documentation
            </Link>
          </Paragraph>

          <Confirm
            label="I’ve created an SSO application in Duo."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 2 && (
        <Article>
          <H1>Step 2: Create a Cloud Application in Duo</H1>

          <Paragraph>
            After configuring the Duo SSO Connection with the IDP of your
            choice, the next step is to create a Cloud Application in Duo. This
            app will handle the connection between WorkOS and Duo.
          </Paragraph>

          <Paragraph>
            Navigate to the Duo Admin Panel and click on Applications on the
            left sidebar. Click on the "Protect an Application" button.
          </Paragraph>

          <Img
            priority
            height={800}
            src="/sso/duo/v1/duo-saml-2.png"
            width={1575}
          />

          <Paragraph>
            Locate the entry for “Generic Service Provider” with a protection
            type of "2FA with SSO hosted by Duo (Single Sign-On)" in the
            applications list. Click "Protect" to the far-right to start
            configuring "Generic Service Provider".
          </Paragraph>

          <Img
            priority
            height={800}
            src="/sso/duo/v1/duo-saml-10.png"
            width={2000}
          />

          <Confirm
            label="I’ve created a Duo Cloud Application."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 3 && (
        <Article>
          <H1>Step 3: Provide the Entity ID from Duo</H1>

          <Paragraph>
            Next, you'll begin to configure the Generic Service Provider
            settings, starting with the Entity ID. The Entity ID comes from the
            Generic Service Provider settings in Duo and needs to be pasted in
            to two places: one in WorkOS and one on this same page in Duo.
          </Paragraph>

          <Paragraph>
            Start by copying the "Entity ID" from the Metadata section of this
            page in Duo and and paste it in to the field under the Service
            Provider section of this same page in Duo that is also called
            "Entity ID".
          </Paragraph>

          <Img
            priority
            height={1100}
            src="/sso/duo/v1/duo-saml-5.png"
            width={1200}
          />

          <Paragraph>
            Now paste this same "Entity ID" value again into the form field
            below.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.saml_entity_id}
              error={errors?.saml_entity_id}
              isInline={true}
              isUpdate={!!errors?.saml_entity_id}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="SP Entity ID"
                name="saml_entity_id"
                onChange={onInputChange}
                placeholder={defaultSPEntityID}
                value={connection?.saml_entity_id}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 4 && (
        <Article>
          <H1>Step 4: Upload the X.509 Certificate from Duo</H1>

          <Paragraph>
            Your X.509 certificate is issued by your Identity Provider and is
            used to verify incoming SAML Responses from the Service Provider.
          </Paragraph>

          <Paragraph>
            You can download the X.509 Certificate from Duo on the same Generic
            Service Provider settings page under Downloads.
          </Paragraph>

          <Img
            priority
            height={1000}
            src="/sso/duo/v1/duo-saml-11.png"
            width={1575}
          />

          <Card>
            <Form
              disabled={!connection?.saml_x509_certs}
              isUpdate={!!errors?.saml_x509_certs}
              onSubmit={onNextStep}
            >
              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 5 && (
        <Article>
          <H1>Step 5: Provide your IdP SSO Endpoint</H1>

          <Paragraph>
            Your IdP SSO Endpoint is the entry point for SAML Requests. Users
            will be redirected here to initiate single sign-on.
          </Paragraph>

          <Paragraph>
            The IdP SSO Endpoint is called the "Single Sign-On URL in Duo" and
            can be found on the same page as the Certificate and Entity ID under
            the Metadata section.
          </Paragraph>

          <Img
            priority
            height={1000}
            src="/sso/duo/v1/duo-saml-6.png"
            width={1575}
          />

          <Paragraph>
            Paste the Single Sign-On URL in to the field below.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.saml_idp_url}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              isInline={true}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_url}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="IdP SSO Endpoint"
                name="saml_idp_url"
                onChange={onInputChange}
                placeholder="https://idp.com/login"
                value={connection?.saml_idp_url}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 6 && (
        <Article>
          <H1>Step 6: Enter the ACS URL in Duo</H1>

          <Paragraph>
            Next, copy the ACS URL from WorkOS and paste it in the Generic
            Service Provider settings towards the bottom of the page under
            "Assertion Consumer Service (ACS) URL"
          </Paragraph>

          <CopyInput
            label="Copy this ACS URL"
            value={connection?.saml_acs_url}
          />

          <Img
            priority
            height={1300}
            src="/sso/duo/v1/duo-saml-4.png"
            width={1575}
          />

          <Paragraph>
            You may leave the Single Logout URL, Service Provider Login URL, and
            Default Relay State fields empty.
          </Paragraph>

          <Confirm
            label="I’ve finished configuring the ACS URL."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 7 && (
        <Article>
          <H1>Step 7: Configure SAML Response Settings in Duo</H1>

          <Paragraph>
            Scroll down on this page in Duo to the SAML Response section. Ensure
            that the NameID format has the id that you’d like to use for the
            unique identifier selected and matches the NameID attribute that
            you’d like to use as the value. If you’re using email as the unique
            id, the options would look like the below.
          </Paragraph>

          <Img
            priority
            height={500}
            src="/sso/duo/v1/duo-saml-7.png"
            width={1700}
          />

          <Paragraph>
            Ensure the Signature algorithm is SHA256 and that the Signing
            options have both Sign response and Sign assertion selected.
          </Paragraph>

          <Img
            priority
            height={500}
            src="/sso/duo/v1/duo-saml-8.png"
            width={2100}
          />

          <Confirm
            label="I’ve finished configuring the SAML response settings."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 8 && (
        <Article>
          <H1>Step 8: Configure the SAML Claims</H1>

          <Paragraph>
            The final step is to make sure that you are mapping the attributes
            which WorkOS requires: <code>id</code>, <code>email</code>,
            <code>firstName</code>, and <code>lastName</code>. In the Map
            Attributes section enter these on the right side under SAML Response
            Attribute. on the left side, click the empty field box and select
            the pre-populated values that look like e.g. "{'<Email Address>'}".
            Duo will automatically grab the corresponding fields and map them to
            the expected values.
          </Paragraph>

          <Paragraph>
            You can map any values you like, but WorkOS requires that these four
            values are included in SAML responses. If your users don’t have a
            last name value for instance, you could map Display Name or any
            other value to <code>lastName</code>, but <code>lastName</code>{' '}
            still needs to be included or WorkOS will reject the SAML Response.
          </Paragraph>

          <Paragraph>
            Ensure the Match attributes section of your General Service Provider
            Application Settings page in Duo aligns with the following.
          </Paragraph>

          <ConnectionAttributeMapping connectionType={connection?.type} />

          <Confirm
            label="I’ve finished configuring the SAML claims."
            onClick={onNextStep}
          />
        </Article>
      )}
    </>
  );
};
