import { space } from '@workos-inc/ui-kit';
import React, { FC, RefObject } from 'react';
import styled from 'styled-components';

type OverflowPageProps = {
  pageRef?: RefObject<HTMLDivElement>;
};

export const OverflowPage: FC<Readonly<OverflowPageProps>> = ({
  children,
  pageRef,
}) => (
  <Container ref={pageRef}>
    <Content>{children}</Content>
  </Container>
);

const Container = styled.div`
  padding: ${space.spacing(8)};
  height: calc(100vh - 60px);
  overflow: auto;
`;

const Content = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;
