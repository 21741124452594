import {
  Colors,
  IconCheck,
  IconCopy,
  Radius,
  space,
  Weight,
} from '@workos-inc/ui-kit';
import copy from 'copy-text-to-clipboard';
import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

export type Attribute = {
  name: string;
  isCopyable?: boolean;
};

export type AttributeMappingProps = {
  labels?: string[];
  attributeGroups: Attribute[][];
};

export const AttributeMapping: React.FC<Readonly<AttributeMappingProps>> = ({
  labels,
  attributeGroups,
}) => (
  <AttributeGrid columns={attributeGroups[0].length}>
    {labels &&
      labels.map((label: string, index) => (
        <>
          <Label key={label}>{label}</Label>
          {index !== labels.length - 1 && <div />}
        </>
      ))}
    {attributeGroups.map((attributes: Attribute[], index) => (
      <Attributes key={index} attributes={attributes} />
    ))}
  </AttributeGrid>
);

type AttributesProps = {
  attributes?: Attribute[];
};

export const Attributes: React.FC<Readonly<AttributesProps>> = ({
  attributes = [],
}) => (
  <>
    {attributes.map((attr: Attribute, index: number) => (
      <>
        <AttributeValue isCopyable={attr.isCopyable} name={attr.name} />
        {index !== attributes.length - 1 && <Icon>→</Icon>}
      </>
    ))}
  </>
);

const AttributeValue = ({
  name,
  isCopyable = true,
}: Attribute): ReactElement => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = (): void => {
    if (!isCopyable) {
      return;
    }

    setTimeout(() => setCopied(false), 1000);

    setCopied(true);
    copy(name);
  };

  let copyComponent = null;

  if (isCopyable) {
    copyComponent = copied ? (
      <IconCheck style={{ stroke: Colors.Green }} />
    ) : (
      <IconCopy />
    );
  }

  return (
    <AttrComponent copied={copied} isCopyable={isCopyable} onClick={handleCopy}>
      <AttrValue>{name}</AttrValue>

      {copyComponent}
    </AttrComponent>
  );
};

const getGridColumns = (columns: number): string => {
  const gridTemplateColumns = [];
  for (let i = 0; i < columns + (columns - 1); i++) {
    if (i % 2) {
      gridTemplateColumns.push('min-content');
    } else {
      gridTemplateColumns.push('1fr');
    }
  }
  return gridTemplateColumns.join(' ');
};

const AttributeGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${(props) => getGridColumns(props.columns)};
  grid-gap: ${space.spacing(4)};
  margin: ${space.spacing(8)} 0;
  align-items: center;
`;

const AttrComponent = styled.div<{ copied: boolean; isCopyable: boolean }>`
  border-radius: ${Radius.Medium};
  border: 1px solid
    ${(props) => (props.copied ? Colors.Green : Colors.TonedLightGray)};
  background-color: ${Colors.TonedLighterGray};
  display: flex;
  flex: 1 0 0;
  align-items: center;
  cursor: default;
  transition: all 250ms;

  svg {
    margin: 0 ${space.spacing(2)};
  }

  ${(props) =>
    props.isCopyable &&
    css`
      cursor: pointer;
      &:hover {
        border-color: ${props.copied ? Colors.Green : Colors.TonedMidGray};
      }
    `}
`;

const AttrValue = styled.span`
  flex: 1;
  font-size: 13px;
  font-weight: ${Weight.Regular};
  padding: 9px ${space.spacing(3)};
  width: 100%;
`;

const Label = styled.label`
  font-weight: ${Weight.Medium};
  font-size: 15px;
  flex: 1;
  margin-bottom: -${space.spacing(2)};
`;

const Icon = styled.i`
  margin: 0 ${space.spacing(4)};
  font-style: inherit;
  font-size: 20px;
`;
