import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const StepOneLoginOAC: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  onFileInput,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <>
    {activeStep === 1 && (
      <>
        <Article>
          <H1>Step 1: Select a SAML Application</H1>

          <Paragraph>
            Log in to the OneLogin Admin Dashboard and select "Applications" in
            the navigation bar.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/onelogin-oac-1.png"
            width={1278}
          />

          <Paragraph>Click "Add App" on the Applications page.</Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-1.png"
            width={1278}
          />

          <Paragraph>
            Search and select the app that you're connecting with from the App
            list.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/onelogin-oac-2.png"
            width={1278}
          />

          <Paragraph>
            Click "Save" to complete adding the {appName} app to your
            application list.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-3.png"
            width={1278}
          />

          <Confirm
            label="I’ve selected a SAML Enterprise Application"
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 2 && (
      <>
        <Article>
          <H1>Step 2: Configure Customer ID in OneLogin</H1>

          <Paragraph>
            Click the “Configuration” tab from the left sidebar.
          </Paragraph>

          <CopyInput
            label="Copy the unique ID from this ACS URL"
            value={connection?.saml_acs_url}
          />

          <Paragraph>
            Submit the “Customer ID” in the Application details and click
            "Save".
          </Paragraph>

          <Paragraph>
            Paste only the customer ID which is the customer specific string at
            the end of the ACS URL.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/onelogin-oac-3.png"
            width={1278}
          />

          <Confirm
            label="I’ve completed the Customer ID Configuration."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 3 && (
      <>
        <Article>
          <H1>Step 5: Upload X.509 Certificate</H1>

          <Paragraph>Click the “SSO” tab from the left sidebar.</Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-11.png"
            width={1278}
          />

          <Paragraph>
            Click “View Details” under the X.509 Certificate.
          </Paragraph>

          <Paragraph>
            Clicking will open a certificate configuration page.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-12.png"
            width={1278}
          />

          <Paragraph>
            Download the X.509 Certificate and upload it below.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.saml_x509_certs}
              isUpdate={!!errors?.saml_x509_certs}
              onSubmit={onNextStep}
            >
              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Form>
          </Card>
        </Article>
      </>
    )}

    {activeStep === 4 && (
      <>
        <Article>
          <H1>Step 4: Provide SAML 2.0 Endpoint</H1>

          <Paragraph>
            Navigate back to the “SSO” tab in your Application and copy the SAML
            2.0 Endpoint.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-13.png"
            width={1278}
          />

          <Card>
            <Form
              disabled={!connection?.saml_idp_url}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              isLoading={isLoading}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="SAML 2.0 Endpoint"
                name="saml_idp_url"
                onChange={onInputChange}
                placeholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
                value={connection?.saml_idp_url}
              />
            </Form>
          </Card>
        </Article>
      </>
    )}
  </>
);
