import { Colors, Radius, space, Weight } from '@workos-inc/ui-kit';
import { Card } from 'components/card';
import { TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Paragraph } from 'components/typography';
import { DirectoryStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'utils/graphql';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsGenericSCIM: ArrayStepsConfig = {
  providerLabel: 'Generic SCIM',
  type: 'Array',
  steps: [
    {
      name: 'Provide Directory Information',
      render: function Step1({ onNextStep }: DirectoryStepProps): JSX.Element {
        const [directorySource, setDirectorySource] = useState('');

        const handleDirectorySubmit = (): void => {
          void graphql().RequestIdentityProvider({
            identityProvider: directorySource,
          });
          onNextStep();
        };

        return (
          <Card>
            <Form
              isInline
              disabled={!directorySource}
              onSubmit={handleDirectorySubmit}
            >
              <TextField
                autoFocus
                label="Which Directory Provider are you connecting to?"
                name="directory_source"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setDirectorySource(event.target.value)
                }
                placeholder="e.g. Okta, Azure AD, Google Workspace..."
                value={directorySource}
              />
            </Form>
          </Card>
        );
      },
    },
    {
      name: 'Configure Directory Provider',
      render: ({ appName, directory, onNextStep }: DirectoryStepProps) => (
        <>
          <StyledBlockquote>
            <svg fill="none" height="12" viewBox="0 0 12 12" width="12">
              <rect fill="#4A4E68" height="12" rx="1" width="12" />
              <path
                // eslint-disable-next-line max-len
                d="M3 4.13153V5.00475H5.43709V9.1322H3V10H9V9.1322H6.66225V4.13153H3ZM5.27815 2.59119C5.27815 2.7539 5.33775 2.88949 5.45695 2.99797C5.58057 3.10644 5.766 3.16068 6.01325 3.16068C6.25607 3.16068 6.43929 3.10644 6.56291 2.99797C6.68653 2.88949 6.74834 2.7539 6.74834 2.59119C6.74834 2.50079 6.73068 2.41763 6.69536 2.34169C6.66004 2.26576 6.60706 2.20249 6.53642 2.15186C6.47903 2.10486 6.40618 2.0687 6.31788 2.04339C6.22958 2.01446 6.12804 2 6.01325 2C5.89845 2 5.7947 2.01446 5.70199 2.04339C5.61369 2.0687 5.54084 2.10486 5.48344 2.15186C5.4128 2.2061 5.35982 2.27119 5.3245 2.34712C5.2936 2.42305 5.27815 2.50441 5.27815 2.59119Z"
                fill="white"
              />
            </svg>
            {appName} authenticates via a Bearer Token passed in the HTTP
            authorization header.
          </StyledBlockquote>

          <Paragraph>
            Provide this endpoint to your directory provider so that it can
            provision users and groups to {appName}.
          </Paragraph>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Paragraph>
            Provide this bearer token to your directory provider so that it can
            authenticate its requests to {appName}.
          </Paragraph>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearer_token}
          />

          {/* eslint-disable-next-line react/no-children-prop */}
          <Form children={null} onSubmit={onNextStep} />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <>
          <Paragraph>
            WorkOS will include all of the fields that Directory Providers
            supply in the "raw_attributes" object of the directory user
            payloads. You can standardize how the application receives these
            "raw_attributes" by configuring custom attribute mappings.
          </Paragraph>

          <Paragraph>
            Each Directory Provider may send different attributes, so the most
            accurate way to know which keys are available to map will be to view
            a directory user payload to see which "raw_attributes" are
            available.
          </Paragraph>

          <Img
            priority
            height={1200}
            src="/directory-sync/bamboo-hr/v1/bamboo-hr-5.png"
            width={1450}
          />

          <Paragraph>
            For Instance, if you wanted to map the "homeEmail" raw attribute,
            you would enter "homeEmail" in the "Directory Provider Value" input
            below.
          </Paragraph>

          <MapAttributesStep {...directoryStepProps} />
        </>
      ),
    },
  ],
};

const StyledBlockquote = styled.blockquote`
  margin-top: ${space.spacing(6)};
  margin-bottom: ${space.spacing(6)};
  border-radius: ${Radius.Medium};
  font-size: 13px;
  font-weight: ${Weight.Regular};
  line-height: 1.6;
  padding: ${space.spacing(3)} ${space.spacing(4)};
  color: ${Colors.GrayDarker};
  background-color: ${Colors.GrayLighter};
  border: 1px solid transparent;
  border-color: ${Colors.GrayDarker};
  display: grid;
  grid-template-columns: 12px 1fr;
  grid-gap: ${space.spacing(2)};

  svg {
    margin-top: ${space.spacing(1)};
    vertical-align: middle;
  }

  a {
    color: #5c8ae5;
  }
  a:visited {
    color: #5c8ae5;
  }
  a:hover {
    filter: brightness(125%);
  }
`;
