import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { ulid } from 'ulid';
import { graphql } from 'utils/graphql';

export const StepGenericSAML: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => {
  const [identityProvider, setIdentityProvider] = useState('');
  const app = appName && appName.toLowerCase().split(' ').join('_');
  const defaultSPEntityID = useMemo(() => `urn:entity:${app}:${ulid()}`, [app]);

  const handleIdPSubmit = (): void => {
    void graphql().RequestIdentityProvider({
      identityProvider,
    });

    onNextStep();
  };

  return (
    <>
      {activeStep === 1 && (
        <Article>
          <H1>Step 1: Provide IdP Information</H1>

          <Card>
            <Form
              disabled={!identityProvider}
              isInline={true}
              onSubmit={handleIdPSubmit}
            >
              <TextField
                autoFocus={true}
                label="Which Identity Provider are you connecting to?"
                name="identity_provider"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setIdentityProvider(event.target.value)
                }
                placeholder="e.g. Okta, Azure AD, OneLogin..."
                value={identityProvider}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 2 && (
        <Article>
          <H1>Step 2: Create a Generic SAML Application</H1>

          <Paragraph>
            Create a generic SAML application in your identity provider. You'll
            need the following Assertion Consumer Service (ACS) URL. The ACS URL
            is the location an Identity Provider redirects its authentication
            response to.
          </Paragraph>

          <CopyInput
            label="Copy this ACS URL"
            value={connection?.saml_acs_url}
          />

          <Confirm
            label="I’ve created a SAML application."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 3 && (
        <Article>
          <H1>Step 3: Provide an SP Entity ID</H1>

          <Paragraph>
            The SP Entity ID is generally a unique value which represents{' '}
            {appName} within your Identity Provider.
          </Paragraph>

          <Paragraph>
            If your Identity Provider does not issue an Entity ID, copy the
            following default SP Entity ID to use in your SAML application, and
            paste it in the SP Entity ID input field below.
          </Paragraph>

          <CopyInput
            label="Copy this default SP Entity ID"
            value={defaultSPEntityID}
          />

          <Paragraph>
            If your Identity Provider does issue an Entity ID, input that value
            below.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.saml_entity_id}
              error={errors?.saml_entity_id}
              isInline={true}
              isUpdate={!!errors?.saml_entity_id}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="SP Entity ID"
                name="saml_entity_id"
                onChange={onInputChange}
                placeholder={defaultSPEntityID}
                value={connection?.saml_entity_id}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 4 && (
        <Article>
          <H1>Step 4: Upload your X.509 Certificate</H1>

          <Paragraph>
            Your X.509 certificate is issued by your Identity Provider and is
            used to verify incoming SAML Responses from the Service Provider.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.saml_x509_certs}
              isUpdate={!!errors?.saml_x509_certs}
              onSubmit={onNextStep}
            >
              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 5 && (
        <Article>
          <H1>Step 5: Provide your IdP SSO Endpoint</H1>

          <Paragraph>
            Your IdP SSO Endpoint is the entry point for SAML Requests. Users
            will be redirected here to initiate single sign-on.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.saml_idp_url}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              isInline={true}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_url}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="IdP SSO Endpoint"
                name="saml_idp_url"
                onChange={onInputChange}
                placeholder="https://idp.com/login"
                value={connection?.saml_idp_url}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 6 && (
        <Article>
          <H1>Step 6: Configure your SAML Claims</H1>

          <Paragraph>
            The final step is to confirm that user attributes map to the
            following SAML claims.
          </Paragraph>

          <ConnectionAttributeMapping connectionType={connection?.type} />

          <Confirm
            label="I’ve finished configuring the SAML claims."
            onClick={onNextStep}
          />
        </Article>
      )}
    </>
  );
};
