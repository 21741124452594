import { Box, Colors, Radius, space } from '@workos-inc/ui-kit';
import styled, { css } from 'styled-components';

export const RadioContainer = styled(Box)<{
  isActive?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${space.spacing(3)};
  border-radius: ${Radius.Small};
  border: 1px solid ${Colors.TonedLightGray};
  transition: 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }

  ${(p) =>
    p.isActive &&
    css`
      border-color: ${Colors.Primary};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }`}
`;
