import { Colors, space } from '@workos-inc/ui-kit';
import Image from 'next/image';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface ImgProps {
  width: number;
  height: number;
  src: string;
  priority?: boolean;
}

const shimmer = (width: number, height: number) => `
<svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eee" offset="20%" />
      <stop stop-color="#f2f2f2" offset="50%" />
      <stop stop-color="#eee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${width}" height="${height}" fill="#eee" />
  <rect id="r" width="${width}" height="${height}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${width}" to="${width}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

const StyledImage = styled.div`
  margin-top: ${space.spacing(6)};
  margin-bottom: ${space.spacing(6)};
  border: 1px solid ${Colors.TonedLightGray};
  display: block;
`;

export const Img: FC<Readonly<ImgProps>> = ({
  src,
  height,
  width,
  priority = false,
}) => (
  <StyledImage>
    <Image
      blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(6, 6))}`}
      height={height}
      placeholder="blur"
      priority={priority}
      src={`/admin-portal${src}`}
      width={width}
    />
  </StyledImage>
);
