import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const StepGoogleSAML: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onInputChange,
  onFileInput,
  onNextStep,
  validationErrors,
}) => (
  <>
    {activeStep === 1 && (
      <>
        <Article>
          <H1>Step 1: Add Custom SAML Application</H1>

          <Paragraph>
            On your Google Admin dashboard, select "Apps" from the sidebar menu,
            and then select "Web and Mobile Apps" from the following list. On
            this page, select "Add App" and then "Add custom SAML app".
          </Paragraph>

          <Img
            priority
            height={440}
            src="/sso/google-saml/v1/google-saml-1-ap.png"
            width={1075}
          />

          <Confirm label="I’ve added a custom SAML app." onClick={onNextStep} />
        </Article>
      </>
    )}

    {activeStep === 2 && (
      <>
        <Article>
          <H1>Step 2: Enter Details for your Custom App</H1>

          <Paragraph>
            Enter an App name and icon (if applicable) for {appName}, then
            select "Continue".
          </Paragraph>

          <Img
            priority
            height={732}
            src="/sso/google-saml/v1/google-saml-2-ap.png"
            width={1390}
          />

          <Confirm label="I’ve entered the App Details." onClick={onNextStep} />
        </Article>
      </>
    )}

    {activeStep === 3 && (
      <>
        <Article>
          <H1>Step 2: Upload Google IdP Information</H1>

          <Paragraph>
            Enter your SSO URL and Entity ID, upload your Certificate, and click
            "Continue".
          </Paragraph>

          <Img
            priority
            height={848}
            src="/sso/google-saml/v1/google-saml-3-ap.png"
            width={1385}
          />

          <Card>
            <Form
              disabled={
                !connection?.saml_idp_url ||
                !connection?.saml_x509_certs ||
                !connection?.saml_entity_id ||
                validationErrors?.saml_idp_url?.value ===
                  connection?.saml_idp_url
              }
              isLoading={isLoading}
              isUpdate={
                !!(
                  errors?.saml_idp_url ||
                  errors?.saml_entity_id ||
                  errors?.saml_x509_certs
                )
              }
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
                label="SSO URL"
                name="saml_idp_url"
                onChange={onInputChange}
                placeholder="https://accounts.google.com/o/saml2/idp?idpid=C00l3qzl9"
                value={connection?.saml_idp_url}
              />

              <TextField
                error={errors?.saml_entity_id}
                label="Entity ID"
                name="saml_entity_id"
                onChange={onInputChange}
                placeholder="https://accounts.google.com/o/saml2?idpid=C00l3qzl9"
                value={connection?.saml_entity_id}
              />

              <FileField
                error={errors?.saml_x509_certs}
                filename="Certificate"
                label="Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Form>
          </Card>
        </Article>
      </>
    )}

    {activeStep === 4 && (
      <>
        <Article>
          <H1>Step 4: Enter Service Provider Details</H1>

          <CopyInput
            label="Copy this Assertion Consumer Service Endpoint"
            value={connection?.saml_acs_url}
          />

          <Paragraph>
            Submit the Assertion Consumer Service Endpoint as the "ACS URL" and
            as the "Entity ID". Then, click "Continue".
          </Paragraph>

          <Img
            height={847}
            src="/sso/google-saml/v1/google-saml-4-ap.png"
            width={1373}
          />

          <Confirm
            label="I’ve configured the ACS URL and Entity ID in my custom SAML app."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 5 && (
      <>
        <Article>
          <H1>Step 5: Configure Attribute Mapping</H1>

          <Paragraph>
            Provide the following Attribute Mappings and select "Finish":
          </Paragraph>

          <ConnectionAttributeMapping connectionType={connection?.type} />

          <Img
            height={728}
            src="/sso/google-saml/v1/google-saml-5-ap.png"
            width={1380}
          />

          <Confirm
            label="I’ve finished configuring the Attribute Mapping."
            onClick={onNextStep}
            submit={true}
          />
        </Article>
      </>
    )}

    {activeStep === 6 && (
      <>
        <Article>
          <H1>Step 6: Configure User Access</H1>

          <Paragraph>
            In the created SAML app's landing page, select the "User Access
            Section".
          </Paragraph>

          <Img
            height={527}
            src="/sso/google-saml/v1/google-saml-6-ap.png"
            width={1443}
          />

          <Paragraph>
            Turn this service ON for the correct organizational units in your
            Google Directory setup. Save any changes. Note that Google may take
            up to 24 hours to propagate these changes, and the connection may be
            inactive until the changes have propagated.
          </Paragraph>

          <Img
            height={361}
            src="/sso/google-saml/v1/google-saml-7-ap.png"
            width={1080}
          />

          <Confirm
            label="I’ve finished configuring the User Access."
            onClick={onNextStep}
            submit={true}
          />
        </Article>
      </>
    )}
  </>
);
