import React from 'react';
import Bugsnag from 'utils/bugsnag';

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary();

export interface BugsnagErrorBoundaryProps {
  children: JSX.Element;
}

export const BugsnagErrorBoundary: React.FC<
  Readonly<BugsnagErrorBoundaryProps>
> = ({ children }) => {
  if (ErrorBoundary) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  } else {
    return <>{children}</>;
  }
};
