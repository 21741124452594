import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const StepPingOne: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <>
    {activeStep === 1 && (
      <Article>
        <H1>Step 1: Create SAML Integration</H1>

        <Paragraph>On the "Applications" tab, click “+”.</Paragraph>

        <Img
          priority
          height={184}
          src="/sso/ping-one/v1/ping-one-1-updated.png"
          width={830}
        />

        <Paragraph>
          Select “Advanced Configuration” and choose the "SAML" connection type.
        </Paragraph>

        <Img
          height={325}
          src="/sso/ping-one/v1/ping-one-2-updated.png"
          width={830}
        />

        <Paragraph>
          Give it a descriptive "Application Name" to easily identify it later
          such as {appName}-{connection?.name}. Then fill out the "Application
          Description."
        </Paragraph>

        <Img
          height={236}
          src="/sso/ping-one/v1/ping-one-3-updated.png"
          width={830}
        />

        <Paragraph>Click "Continue to Next Step".</Paragraph>

        <Confirm
          label="I’ve finished creating the SAML Integration
          and clicked “Continue to Next Step”."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 2 && (
      <Article>
        <H1>Step 2: Submit ACS URL and Entity ID</H1>

        <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

        <CopyInput
          label="Copy this Entity ID"
          value={connection?.saml_entity_id}
        />

        <Paragraph>
          Submit ACS URL as the “Assertion Consumer Service (ACS) URL” and the
          Entity ID as the “Entity ID”.
        </Paragraph>

        <Img
          height={442}
          src="/sso/ping-one/v1/ping-one-4-updated.png"
          width={830}
        />

        <Paragraph>Click "Continue to Next Step".</Paragraph>

        <Confirm
          label="I’ve configured the Assertion Consumer Service (ACS) URL and
          Entity ID and clicked ”Continue to Next Step”."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 3 && (
      <Article>
        <H1>Step 3: Configure Attribute Statements</H1>

        <Paragraph>
          On the “SSO Attribute Mapping” step, click on the "Add new attribute"
          button to add new attributes to map.
        </Paragraph>

        <Img
          priority
          height={477}
          src="/sso/ping-one/v1/ping-one-5-updated.png"
          width={830}
        />

        <Paragraph>
          Fill in the following Attribute Statements and click “Continue to Next
          Step”:
        </Paragraph>

        <ConnectionAttributeMapping connectionType={connection?.type} />

        <Confirm
          label="I’ve finished configuring the Attribute Statements
          and clicked “Continue to Next Step”."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 4 && (
      <Article>
        <H1>Step 4: Upload Identity Provider Metadata</H1>

        <Paragraph>
          At the end of the Application Setup Wizard, you’ll be given an
          overview of your Identity Provider metadata. Copy the “Initiate Single
          Sign-On (SSO) URL” to use as the Identity Provider Single Sign-On URL
          below.
        </Paragraph>

        <Paragraph>
          Download the “Signing Certificate” to use as the X.509 Certificate
          below.
        </Paragraph>

        <Img
          height={498}
          src="/sso/ping-one/v1/ping-one-6-updated.png"
          width={830}
        />

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              !connection?.saml_entity_id ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={
              !!(
                errors?.saml_idp_url ||
                errors?.saml_entity_id ||
                errors?.saml_x509_certs
              )
            }
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="1. Identity Provider Single Sign-On URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
              value={connection?.saml_idp_url}
            />

            <FileField
              error={errors?.saml_x509_certs}
              filename="X.509 Certificate"
              label="3. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    )}
  </>
);
