import { Box, Colors, Radius, space } from '@workos-inc/ui-kit';
import styled from 'styled-components';

export const Card = styled(Box)`
  padding: ${space.spacing(6)} ${space.spacing(10)};
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.12);
  background-color: ${Colors.White};
  border-radius: ${Radius.Medium};
  margin: ${space.spacing(8)} -${space.spacing(10)};
`;
