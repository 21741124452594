import { ConnectionType } from 'graphql/generated';

type Config = Partial<
  Record<
    ConnectionType,
    {
      labels?: string[];
      attributeGroups: { name: string; isCopyable?: boolean }[][];
    }
  >
>;

export const attributeMappingConfig: Config = {
  [ConnectionType.OktaSaml]: {
    labels: ['Name', 'Value'],
    attributeGroups: [
      [{ name: 'id' }, { name: 'user.id' }],
      [{ name: 'email' }, { name: 'user.email' }],
      [{ name: 'firstName' }, { name: 'user.firstName' }],
      [{ name: 'lastName' }, { name: 'user.lastName' }],
    ],
  },
  [ConnectionType.AzureSaml]: {
    labels: ['Claim name', 'Value'],
    attributeGroups: [
      [
        {
          name:
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
        },
        {
          name: 'user.mail',
        },
      ],
      [
        {
          name:
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
        },
        { name: 'user.givenname' },
      ],
      [
        {
          name: 'http://schemas.microsoft.com/identity/claims/name',
        },
        {
          name: 'user.userprincipalname',
        },
      ],
      [
        {
          name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
        },
        {
          name: 'user.surname',
        },
      ],
    ],
  },
  [ConnectionType.Adfssaml]: {
    labels: ['LDAP Attribute', 'Outgoing Claim Type'],
    attributeGroups: [
      [{ name: 'E-Mail-Addresses' }, { name: 'E-Mail Address' }],
      [{ name: 'Given-Name' }, { name: 'Given Name' }],
      [{ name: 'Surname' }, { name: 'Surname' }],
      [{ name: 'User-Principal-Name' }, { name: 'UPN' }],
    ],
  },
  [ConnectionType.GenericSaml]: {
    labels: ['Name', 'Value'],
    attributeGroups: [
      [
        {
          name: 'A unique identifier representing a user',
        },
        {
          name: 'id',
        },
      ],
      [{ name: 'A user’s email address' }, { name: 'email' }],
      [{ name: 'A user’s first name' }, { name: 'firstName' }],
      [{ name: 'A user’s last name' }, { name: 'lastName' }],
    ],
  },
  [ConnectionType.CyberArkSaml]: {
    labels: ['Attribute Name', 'Attribute Value'],
    attributeGroups: [
      [{ name: 'id' }, { name: 'LoginUser.Uuid' }],
      [{ name: 'email' }, { name: 'LoginUser.Email' }],
      [{ name: 'firstName' }, { name: 'LoginUser.FirstName' }],
      [{ name: 'lastName' }, { name: 'LoginUser.LastName' }],
    ],
  },
  [ConnectionType.DuoSaml]: {
    labels: ['IdP Attribute', 'SAML Response Attribute'],
    attributeGroups: [
      [{ name: '<Email Address>' }, { name: 'id' }],
      [{ name: '<Email Address>' }, { name: 'Email' }],
      [{ name: '<First Name>' }, { name: 'firstName' }],
      [{ name: '<Last Name>' }, { name: 'lastName' }],
    ],
  },
  [ConnectionType.GoogleSaml]: {
    attributeGroups: [
      [
        {
          name: 'Primary email',
          isCopyable: false,
        },
        {
          name: 'email',
        },
      ],
      [
        {
          name: 'First name',
          isCopyable: false,
        },
        {
          name: 'firstName',
        },
      ],
      [
        {
          name: 'Last name',
          isCopyable: false,
        },
        {
          name: 'lastName',
        },
      ],
    ],
  },
  [ConnectionType.JumpCloudSaml]: {
    labels: ['Service Provider Attribute Name', 'JumpCloud Attribute Name'],
    attributeGroups: [
      [{ name: 'id' }, { name: 'email' }],
      [{ name: 'email' }, { name: 'email' }],
      [{ name: 'firstName' }, { name: 'firstname' }],
      [{ name: 'lastName' }, { name: 'lastname' }],
    ],
  },
  [ConnectionType.OneLoginSaml]: {
    labels: ['SAML Test Connector (Advanced) Field', 'Value'],
    attributeGroups: [
      [{ name: 'id' }, { name: 'UUID' }],
      [{ name: 'email' }, { name: 'Email' }],
      [{ name: 'firstName' }, { name: 'First Name' }],
      [{ name: 'lastName' }, { name: 'Last Name' }],
    ],
  },
  [ConnectionType.PingFederateSaml]: {
    labels: ['Extend the Contract', 'Attribute Name Format'],
    attributeGroups: [
      [
        { name: 'email' },
        { name: 'urn:oasis:names:tc:SAML:2.0:attrname-format:basic' },
      ],
      [
        { name: 'firstName' },
        { name: 'urn:oasis:names:tc:SAML:2.0:attrname-format:basic' },
      ],
      [
        { name: 'id' },
        { name: 'urn:oasis:names:tc:SAML:2.0:attrname-format:basic' },
      ],
      [
        { name: 'lastName' },
        { name: 'urn:oasis:names:tc:SAML:2.0:attrname-format:basic' },
      ],
    ],
  },
  [ConnectionType.PingOneSaml]: {
    labels: ['Application Attribute', 'PingOne User Attribute'],
    attributeGroups: [
      [{ name: 'id' }, { name: 'User ID' }],
      [{ name: 'firstName' }, { name: 'Given Name' }],
      [{ name: 'lastName' }, { name: 'Family Name' }],
      [{ name: 'email' }, { name: 'Email Address' }],
    ],
  },
  [ConnectionType.RipplingSaml]: {
    labels: ['Attribute Name', 'Attribute Value'],
    attributeGroups: [
      [{ name: 'id' }, { name: "User's ID" }],
      [{ name: 'firstName' }, { name: "User's Legal first name" }],
      [{ name: 'lastName' }, { name: " User's Legal last name" }],
      [{ name: 'email' }, { name: "User's email address" }],
    ],
  },
  [ConnectionType.VMwareSaml]: {
    labels: ['Name', 'Value'],
    attributeGroups: [
      [{ name: 'id' }, { name: '${user.objectGUID}' }],
      [{ name: 'email' }, { name: '${user.email}' }],
      [{ name: 'firstName' }, { name: '${user.firstName}' }],
      [{ name: 'lastName' }, { name: '${user.lastName}' }],
    ],
  },
};
