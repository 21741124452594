import { Box, Grid, space } from '@workos-inc/ui-kit';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Radio } from 'components/radio';
import { Article, H1, LI, OL, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { useState } from 'react';
import { useFeature } from 'utils/feature-flags';

export const StepOkta: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => {
  const [selectedConfiguration, setSelectedConfiguration] = useState('dynamic');

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  return (
    <>
      {activeStep === 1 && (
        <Article>
          <H1>Step 1: Create SAML Integration</H1>

          <Img
            priority
            height={313}
            src="/sso/okta/v1/okta-new-10.png"
            width={1438}
          />

          <Paragraph>
            Click "Create App Integration" from your Okta Application dashboard.
          </Paragraph>

          <Img
            priority
            height={759}
            src="/sso/okta/v1/okta-2.png"
            width={1213}
          />

          <Paragraph>Click “Create New App”.</Paragraph>

          <Img height={590} src="/sso/okta/v1/okta-new-11.png" width={1038} />

          <Paragraph>
            Select "SAML 2.0" as a Sign on method, then click "Next".
          </Paragraph>

          <Img height={813} src="/sso/okta/v1/okta-4.png" width={825} />

          <Paragraph>
            Enter an App name that describes {appName}, then click "Next".
          </Paragraph>

          <CopyInput
            label="Copy this Single sign on URL"
            value={connection?.saml_acs_url}
          />

          <Paragraph>
            Submit the Single Sign on URL as the "Single sign on URL" and as the
            "Audience URI".
          </Paragraph>

          <Img height={725} src="/sso/okta/v1/okta-5.png" width={750} />

          <Confirm
            label="I've configured the Single sign on URL and Audience URI."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 2 && (
        <Article>
          <H1>Step 2: Configure Attribute Statements</H1>

          <Paragraph>
            Continue scrolling to the Attribute Statements configuration.
          </Paragraph>

          <Img
            priority
            height={439}
            src="/sso/okta/v1/okta-6.png"
            width={720}
          />

          <Paragraph>
            Fill in the following Attribute Statements and click "Next":
          </Paragraph>

          <ConnectionAttributeMapping connectionType={connection?.type} />

          <Confirm
            label="I've finished configuring the Attribute Statements
          and clicked “Next”."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 3 && (
        <Article>
          <H1>Step 3: Submit Application Feedback</H1>

          <Paragraph>
            Select "I'm an Okta customer adding an internal app" from the
            options menu.
          </Paragraph>

          <Img
            priority
            height={235}
            src="/sso/okta/v1/okta-7.png"
            width={733}
          />

          <Paragraph>
            Complete the form with any comments and select “Finish”.
          </Paragraph>

          <Confirm
            label="I’ve completed the feedback formed and clicked “Finish”."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 4 && (
        <Article>
          <H1>Step 4: Add Users to SAML App</H1>

          <Paragraph>
            In order to test out SSO authentication, you will first need to
            assign your Okta SAML app to Groups.
          </Paragraph>

          <Img
            priority
            height={389}
            src="/sso/okta/v1/okta-8.png"
            width={743}
          />

          <Paragraph>
            Click on the "Assignments" tab of the app and then select "Assign to
            Groups".
          </Paragraph>

          <Img height={590} src="/sso/okta/v1/okta-9.png" width={608} />

          <Paragraph>
            Find the Group(s) that you would like to assign to the app and click
            "Assign" next to it. Click "Done" when you're finished.
          </Paragraph>

          <Confirm
            label="I’ve completed the feedback formed and clicked “Finish”."
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 5 && isInputSamlCertificateMetadataUrlEnabled && (
        <Article>
          <H1>Step 5: Upload Identity Provider Metadata</H1>

          <Paragraph>
            The final step for implementing SAML SSO requires sharing your
            identity provider's metadata with the application. Begin by
            selecting the kind of configuration you would like.
          </Paragraph>

          <Radio.Group
            aria-label="Select what type of configuration you would like to use"
            onValueChange={setSelectedConfiguration}
            value={selectedConfiguration}
          >
            <Grid gridGap={space.spacing(5)} gridTemplateColumns="1fr 1fr">
              <Radio isActive={selectedConfiguration === 'dynamic'}>
                <Radio.GroupRadio id="dynamic-configuration" value="dynamic">
                  <Radio.GroupIndicator />
                </Radio.GroupRadio>

                <Box
                  as="label"
                  display="flex"
                  flexDirection="column"
                  htmlFor="dynamic-configuration"
                >
                  <Radio.Title>Dynamic configuration (Recommended)</Radio.Title>
                  <Radio.Description>Using metadata URL</Radio.Description>
                </Box>
              </Radio>

              <Radio isActive={selectedConfiguration === 'dynamic'}>
                <Radio.GroupRadio id="manual-configuration" value="manual">
                  <Radio.GroupIndicator />
                </Radio.GroupRadio>

                <Box
                  as="label"
                  display="flex"
                  flexDirection="column"
                  htmlFor="manual-configuration"
                >
                  <Radio.Title>Manual configuration</Radio.Title>
                  <Radio.Description>
                    Using certificate upload
                  </Radio.Description>
                </Box>
              </Radio>
            </Grid>
          </Radio.Group>

          {selectedConfiguration === 'dynamic' && (
            <>
              <OL>
                <LI>
                  Click on the “Sign On” tab of the SAML app you just created.
                </LI>
                <LI>
                  Click the “Identity Provider metadata” link below the View
                  Setup Instructions button.
                </LI>
                <LI>
                  A separate tab will open. Copy the link and paste it below.
                </LI>
              </OL>

              <Img
                height={1066}
                src="/sso/okta/v1/saml-metadata-url.png"
                width={2560}
              />

              <Card>
                <Form
                  disabled={!connection?.saml_idp_metadata_url}
                  isLoading={isLoading}
                  isUpdate={!!errors?.saml_idp_metadata_url}
                  onSubmit={onNextStep}
                >
                  <TextField
                    error={errors?.saml_idp_metadata_url}
                    label="IdP Metadata URL"
                    name="saml_idp_metadata_url"
                    onChange={onInputChange}
                    placeholder="https://"
                    value={connection?.saml_idp_metadata_url}
                  />
                </Form>
              </Card>
            </>
          )}

          {selectedConfiguration === 'manual' && (
            <>
              <Img
                priority
                height={694}
                src="/sso/okta/v1/okta-10.png"
                width={828}
              />

              <Paragraph>
                A separate tab will open with your Identity Provider metadata.
              </Paragraph>

              <Img height={702} src="/sso/okta/v1/okta-11.png" width={635} />

              <Card>
                <Form
                  disabled={
                    !connection?.saml_idp_url ||
                    !connection?.saml_x509_certs ||
                    !connection?.saml_entity_id ||
                    validationErrors?.saml_idp_url?.value ===
                      connection?.saml_idp_url
                  }
                  isLoading={isLoading}
                  isUpdate={
                    !!(
                      errors?.saml_idp_url ||
                      errors?.saml_entity_id ||
                      errors?.saml_x509_certs
                    )
                  }
                  onSubmit={onNextStep}
                >
                  <TextField
                    error={
                      errors?.saml_idp_url || validationErrors?.saml_idp_url
                    }
                    label="1. Identity Provider Single Sign-On URL"
                    name="saml_idp_url"
                    onChange={onInputChange}
                    placeholder="https://foo-corp.okta.com/app/exkgq2/sso/saml"
                    value={connection?.saml_idp_url}
                  />

                  <TextField
                    error={errors?.saml_entity_id}
                    label="2. Identity Provider Issuer"
                    name="saml_entity_id"
                    onChange={onInputChange}
                    placeholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
                    value={connection?.saml_entity_id}
                  />

                  <FileField
                    error={errors?.saml_x509_certs}
                    filename="X.509 Certificate"
                    label="3. X.509 Certificate"
                    name="saml_x509_certs"
                    onUpload={onFileInput}
                    value={connection?.saml_x509_certs?.[0]}
                  />
                </Form>
              </Card>
            </>
          )}
        </Article>
      )}

      {activeStep === 5 && !isInputSamlCertificateMetadataUrlEnabled && (
        <Article>
          <H1>Step 5: Upload Identity Provider Metadata</H1>

          <Paragraph>
            Click on the "Sign On" tab of the SAML app and select "View Setup
            Instructions".
          </Paragraph>

          <Img
            priority
            height={694}
            src="/sso/okta/v1/okta-10.png"
            width={828}
          />

          <Paragraph>
            A separate tab will open with your Identity Provider metadata.
          </Paragraph>

          <Img height={702} src="/sso/okta/v1/okta-11.png" width={635} />

          <Card>
            <Form
              disabled={
                !connection?.saml_idp_url ||
                !connection?.saml_x509_certs ||
                !connection?.saml_entity_id ||
                validationErrors?.saml_idp_url?.value ===
                  connection?.saml_idp_url
              }
              isLoading={isLoading}
              isUpdate={
                !!(
                  errors?.saml_idp_url ||
                  errors?.saml_entity_id ||
                  errors?.saml_x509_certs
                )
              }
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
                label="1. Identity Provider Single Sign-On URL"
                name="saml_idp_url"
                onChange={onInputChange}
                placeholder="https://foo-corp.okta.com/app/exkgq2/sso/saml"
                value={connection?.saml_idp_url}
              />

              <TextField
                error={errors?.saml_entity_id}
                label="2. Identity Provider Issuer"
                name="saml_entity_id"
                onChange={onInputChange}
                placeholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
                value={connection?.saml_entity_id}
              />

              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="3. X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Form>
          </Card>
        </Article>
      )}
    </>
  );
};
