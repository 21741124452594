import { ErrorFields } from 'interfaces/error-fields';
import { ConnectionStepProps } from 'interfaces/step-props';
import { SupportedConnectionType } from 'interfaces/supported-connection-type';
import { FC } from 'react';
import { StepADFS } from './step-adfs';
import { StepAdpOIDC } from './step-adp-oidc';
import { StepAuth0 } from './step-auth0';
import { StepAzure } from './step-azure';
import { StepCyberArk } from './step-cyberark';
import { StepDuoSAML } from './step-duo-saml';
import { StepGenericSAML } from './step-generic-saml';
import { StepGoogleSAML } from './step-google-saml';
import { StepJumpCloud } from './step-jumpcloud';
import { StepOkta } from './step-okta';
import { StepOktaOIN } from './step-okta-oin';
import { StepOneLogin } from './step-onelogin';
import { StepOneLoginOAC } from './step-onelogin-oac';
import { StepOpenID } from './step-openid';
import { StepPingFederate } from './step-pingfederate';
import { StepPingOne } from './step-pingone';
import { StepRippling } from './step-rippling';
import { StepVMWare } from './step-vmware';

export interface StepConfig {
  Component: FC<ConnectionStepProps>;
  errorFields: ErrorFields;
  providerLabel: string;
  steps: { name: string }[];
}

type Overrides = 'OneLoginOAC' | 'OktaOIN';

type StepsConfig = {
  [key in SupportedConnectionType]: StepConfig;
};

type StepsConfigOverrides = {
  [key in Overrides]: StepConfig;
};

export const stepsConfig: StepsConfig = {
  OktaSAML: {
    Component: StepOkta,
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 5,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 5,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 5,
      },
    },
    providerLabel: 'Okta',
    steps: [
      { name: 'Create SAML Integration' },
      { name: 'Configure Attribute Statements' },
      { name: 'Submit Application Feedback' },
      { name: 'Add Users to SAML App' },
      { name: 'Upload Identity Provider Metadata' },
    ],
  },
  GoogleSAML: {
    Component: StepGoogleSAML,
    errorFields: {
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 3,
      },
    },
    providerLabel: 'Google SAML',
    steps: [
      { name: 'Add Custom SAML Application' },
      { name: 'Enter Details for your Custom App' },
      { name: 'Upload Google IdP Information' },
      { name: 'Enter Service Provider Details' },
      { name: 'Configure Attribute Mapping' },
      { name: 'Configure User Access' },
    ],
  },
  AzureSAML: {
    Component: StepAzure,
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 4,
      },
      saml_idp_url: {
        label: 'Login URL',
        step: 5,
      },
    },
    providerLabel: 'Azure AD',
    steps: [
      { name: 'Create Enterprise Application' },
      { name: 'Configure Attribute Statements' },
      { name: 'User Attributes & Claims' },
      { name: 'Assign People & Groups' },
      { name: 'SAML Signing Certificate' },
      { name: 'Provide a Login URL' },
    ],
  },
  ADFSSAML: {
    Component: StepADFS,
    errorFields: {
      saml_x509_certs: {
        label: 'Token Signing Certificate',
        step: 3,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 4,
      },
    },
    providerLabel: 'Microsoft AD FS',
    steps: [
      { name: 'Configure a Relying Party Trust' },
      { name: 'Choose Access Policy' },
      { name: 'Configure Claims Issuance Policy' },
      { name: 'Upload Token Signing Certificate' },
      { name: 'Provide SAML 2.0 Endpoint' },
    ],
  },
  GenericSAML: {
    Component: StepGenericSAML,
    errorFields: {
      saml_entity_id: {
        label: 'SP Entity ID',
        step: 3,
      },
      saml_x509_certs: {
        label: 'Certificate',
        step: 4,
      },
      saml_idp_url: {
        label: 'IdP SSO Endpoint',
        step: 5,
      },
    },
    providerLabel: 'Generic SAML',
    steps: [
      { name: 'Provide IdP Information' },
      { name: 'Create a Generic SAML Application' },
      { name: 'Provide an SP Entity ID' },
      { name: 'Upload your X.509 Certificate' },
      { name: 'Provide your IdP SSO Endpoint' },
      { name: 'Configure your SAML Claims' },
    ],
  },
  DuoSAML: {
    Component: StepDuoSAML,
    errorFields: {
      saml_entity_id: {
        label: 'SP Entity ID',
        step: 3,
      },
      saml_x509_certs: {
        label: 'Certificate',
        step: 4,
      },
      saml_idp_url: {
        label: 'IdP SSO Endpoint',
        step: 5,
      },
    },
    providerLabel: 'Duo SAML',
    steps: [
      { name: 'Create an SSO Configuration in Duo' },
      { name: 'Create a Cloud Application in Duo' },
      { name: 'Provide the Entity ID from Duo' },
      { name: 'Upload the X.509 Certificate from Duo' },
      { name: 'Provide the IdP SSO Endpoint from Duo' },
      { name: 'Enter the ACS URL in Duo' },
      { name: 'Configure SAML Response Settings in Duo' },
      { name: 'Configure the SAML Claims' },
    ],
  },
  AdpOidc: {
    Component: StepAdpOIDC,
    errorFields: {
      oidc_redirect_uri: {
        label: 'Redirect URI',
        step: 2,
      },
      oidc_client_id: {
        label: 'Client ID',
        step: 3,
      },
      oidc_client_secret: {
        label: 'Client Secret',
        step: 3,
      },
      oidc_ssl_cert: {
        label: 'ADP SSL Certificate',
        step: 4,
      },
      oidc_private_key: {
        label: 'ADP Private Key',
        step: 4,
      },
    },
    providerLabel: 'ADP OIDC',
    steps: [
      { name: 'Create a Project in the ADP Apps Portal' },
      { name: 'Configure the Redirect URI in ADP' },
      { name: 'Provide the Client ID and Secret' },
      { name: 'Upload the SSL Certificate and Private Key' },
    ],
  },
  JumpCloudSAML: {
    Component: StepJumpCloud,
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 4,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 4,
      },
    },
    providerLabel: 'JumpCloud',
    steps: [
      { name: 'Create SAML Application' },
      { name: 'Submit ACS URL and Entity ID' },
      { name: 'Configure Attribute Statements' },
      { name: 'Upload Identity Provider Metadata' },
    ],
  },
  OneLoginSAML: {
    Component: StepOneLogin,
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 5,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 6,
      },
    },
    providerLabel: 'OneLogin',
    steps: [
      { name: 'Create a SAML Application' },
      { name: 'Basic SAML Configuration' },
      { name: 'Advanced SAML Configuration' },
      { name: 'Configure Parameters' },
      { name: 'Upload X.509 Certificate' },
      { name: 'Provide SAML 2.0 Endpoint' },
    ],
  },
  PingOneSAML: {
    Component: StepPingOne,
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 5,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 5,
      },
    },
    providerLabel: 'PingOne',
    steps: [
      { name: 'Create SAML Integration' },
      { name: 'Submit ACS URL and Entity ID' },
      { name: 'Configure Attribute Statements' },
      { name: 'Upload Identity Provider Metadata' },
    ],
  },
  PingFederateSAML: {
    Component: StepPingFederate,
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 5,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 5,
      },
    },
    providerLabel: 'PingFederate',
    steps: [
      { name: 'Create SAML SP Connection' },
      { name: 'Configure Browser SSO Settings' },
      { name: 'Configure Assertion Creation' },
      { name: 'Configure Protocol Settings' },
      { name: 'Configure Credentials' },
      { name: 'Upload IdP Data' },
    ],
  },
  VMwareSAML: {
    Component: StepVMWare,
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 5,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 6,
      },
    },
    providerLabel: 'VMWare Workspace ONE',
    steps: [
      { name: 'Create a new SaaS Application' },
      { name: 'Basic SAML Configuration' },
      { name: 'Advanced SAML Configuration' },
      { name: 'Configure Attribute Map' },
      { name: 'Upload X.509 Certificate' },
      { name: 'Provide a Launch URL' },
    ],
  },
  GenericOIDC: {
    Component: StepOpenID,
    errorFields: {
      oidc_redirect_uri: {
        label: 'Redirect URI',
        step: 2,
      },
      oidc_client_id: {
        label: 'Client ID',
        step: 3,
      },
      oidc_client_secret: {
        label: 'Client Secret',
        step: 3,
      },
      oidc_discovery_endpoint: {
        label: 'Discovery Endpoint',
        step: 4,
      },
    },
    providerLabel: 'OpenID Connect',
    steps: [
      { name: 'Provide IdP Information' },
      { name: 'Create an Application with your IdP' },
      { name: 'Provide your Client Credentials' },
      { name: 'Provide your Discovery Endpoint' },
    ],
  },
  CyberArkSAML: {
    Component: StepCyberArk,
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 4,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 4,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 4,
      },
    },
    providerLabel: 'CyberArk',
    steps: [
      { name: 'Create SAML Integration' },
      { name: 'Configure Attribute Statements' },
      { name: 'Add Users to SAML App' },
      { name: 'Upload Identity Provider Metadata' },
    ],
  },
  RipplingSAML: {
    Component: StepRippling,
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 4,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 4,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 4,
      },
    },
    providerLabel: 'Rippling',
    steps: [
      { name: 'Create SAML Integration' },
      { name: 'Configure Attribute Statements' },
      { name: 'Add Users to SAML App' },
      { name: 'Upload Identity Provider Metadata' },
    ],
  },
  Auth0SAML: {
    Component: StepAuth0,
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 2,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 2,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 2,
      },
    },
    providerLabel: 'Auth0',
    steps: [
      { name: 'Create SAML Integration' },
      { name: 'Upload Identity Provider Metadata' },
    ],
  },
};

export const stepsConfigOverrides: StepsConfigOverrides = {
  OktaOIN: {
    Component: StepOktaOIN,
    errorFields: {},
    providerLabel: 'Okta',
    steps: [{ name: 'Setup Okta Application' }],
  },
  OneLoginOAC: {
    Component: StepOneLoginOAC,
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 3,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 4,
      },
    },
    providerLabel: 'OneLogin',
    steps: [
      { name: 'Add the SAML Application' },
      { name: 'Configure Customer ID in OneLogin' },
      { name: 'Upload X.509 Certificate' },
      { name: 'Provide SAML 2.0 Endpoint' },
    ],
  },
};
