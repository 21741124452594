import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { AdpCertUploadForm } from './adp-oidc-form/configure-adp-cert';

export const StepAdpOIDC: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  connection,
  errors,
  isLoading,
  onInputChange,
  onNextStep,
}) => (
  <>
    {activeStep === 1 && (
      <Article>
        <H1>Step 1: Create a Project in the ADP Apps Portal</H1>

        <Paragraph>
          Login to the{' '}
          <Link
            newTab
            appearance="highlight"
            aria-label="Link to the ADP Apps Portal"
            href="https://adpapps.adp.com/self-service/projects"
          >
            ADP Partner Self Service Portal
          </Link>
        </Paragraph>

        <Paragraph>
          From this page there are two environments to select from: Development
          and Production. Please use the environment that best suits your
          use-case for this SSO connection.
        </Paragraph>

        <Img
          priority
          height={710}
          src="/sso/adp-oidc/v1/adp-oidc-1.png"
          width={1000}
        />

        <Paragraph>
          In the selected environment select "Create New Project".
        </Paragraph>

        <Img
          priority
          height={710}
          src="/sso/adp-oidc/v1/adp-oidc-2.png"
          width={1000}
        />

        <Paragraph>
          Give the project a meaningful name to designate the SSO connection.
          There is no need to add a description.
        </Paragraph>

        <Img
          priority
          height={710}
          src="/sso/adp-oidc/v1/adp-oidc-3.png"
          width={1000}
        />

        <Paragraph>
          Make the selections "US Marketplace" and "ADP Workforce Now"
          respectively for the next selections and then click "Next".
        </Paragraph>

        <Img
          priority
          height={810}
          src="/sso/adp-oidc/v1/adp-oidc-4.png"
          width={900}
        />

        <Paragraph>
          Finally, select "Other" for the use case that best describes your
          application and click "Submit".
        </Paragraph>

        <Confirm
          label="I’ve created a new application in ADP."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 2 && (
      <Article>
        <H1>Step 2: Configure the Redirect URI in ADP</H1>

        <Paragraph>
          In this step you'll navigate to the SSO configurations in ADP and add
          the Redirect URI provided below.
        </Paragraph>

        <Img
          priority
          height={500}
          src="/sso/adp-oidc/v1/adp-oidc-5.png"
          width={800}
        />

        <CopyInput
          label="Copy this Redirect URI"
          value={connection?.oidc_redirect_uri}
        />

        <Paragraph>
          Now that a new project has been created browse to the "Development API
          Credentials" Tab within the project.
        </Paragraph>

        <Paragraph>
          Click on the "End-User/SSO sub-tab" from this view.
        </Paragraph>

        <Paragraph>
          Paste the Redirect URI into the App redirect URI field and click
          "Update Redirect".
        </Paragraph>

        <Confirm
          label="I’ve added the Redirect URI into ADP."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 3 && (
      <Article>
        <H1>Step 3: Provide the Client ID and Secret from ADP</H1>

        <Paragraph>
          Next, under the same tab used in the previous step, you'll provide the
          Client ID and Client Secret from ADP.
        </Paragraph>

        <Img
          priority
          height={800}
          src="/sso/adp-oidc/v1/adp-oidc-6.png"
          width={1250}
        />

        <Paragraph>
          Simply click to reveal the secret and copy and paste both the client
          ID and client secret into the fields below.
        </Paragraph>

        <Card>
          <Form
            disabled={
              !connection?.oidc_client_id || !connection?.oidc_client_secret
            }
            isLoading={isLoading}
            isUpdate={!!(errors?.oidc_client_id || errors?.oidc_client_secret)}
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.oidc_client_id}
              label="Client ID"
              name="oidc_client_id"
              onChange={onInputChange}
              value={connection?.oidc_client_id}
            />

            <TextField
              error={errors?.oidc_client_secret}
              label="Client Secret"
              name="oidc_client_secret"
              onChange={onInputChange}
              value={connection?.oidc_client_secret}
            />
          </Form>
        </Card>
      </Article>
    )}

    {activeStep === 4 && (
      <Article>
        <H1>Step 4: Upload the SSL Certificate and Private Key from ADP</H1>

        <Paragraph>
          ADP uses a two fold certificate method with an SSL certificate and an
          SSL Private Key. The SSL Private Key is only displayed one time when
          the certificate is generated. If the certificate has already been
          generated, the IT Administrator who generated it should have also
          received the Private Key otherwise a new certificate and key can be
          generated by browsing to the certificates tab on the left hand
          navigation.
        </Paragraph>

        <Paragraph>
          You can download the SSL Certificate from ADP by browsing to
          'Certificate' on the left hand nav bar. You can also create a new SSL
          Certificate and Private Key pair if necessary.
        </Paragraph>

        <Img
          priority
          height={642}
          src="/sso/adp-oidc/v1/adp-oidc-7.png"
          width={1216}
        />

        <AdpCertUploadForm
          connectionId={connection?.id || ''}
          onNextStep={onNextStep}
        />
      </Article>
    )}
  </>
);
