import { Colors, Weight } from '@workos-inc/ui-kit';
import { Paragraph } from 'components/typography';
import styled from 'styled-components';

export const RadioTitle = styled(Paragraph)`
  margin: 0;
  font-weight: ${Weight.Medium};
  font-size: 14px;
  color: ${Colors.GrayDarker};
`;
