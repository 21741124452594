import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const StepAzure: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <>
    {activeStep === 1 && (
      <>
        <Article>
          <H1>Step 1: Create Enterprise Application</H1>

          <Paragraph>
            Select "Enterprise applications" from your Azure AD dashboard.
          </Paragraph>

          <Img
            priority
            height={1280}
            src="/sso/azure/v1/azure-1.png"
            width={2234}
          />

          <Paragraph>Click "New application" and continue.</Paragraph>

          <Img
            priority
            height={1084}
            src="/sso/azure/v1/azure-2.png"
            width={2222}
          />

          <Paragraph>
            Select "Create your own application", then enter an App name that
            describes {appName}. Under "What are you looking to do with your
            application?", select "Integrate any other application you don't
            find in the gallery (Non-gallery)", then select "Create".
          </Paragraph>

          <Img height={524} src="/sso/azure/v1/azure-3.png" width={1148} />

          <Paragraph>
            Next, select "Single Sign On" from the "Manage" section in the left
            sidebar navigation menu, and then "SAML".
          </Paragraph>

          <Img height={1098} src="/sso/azure/v1/azure-4.png" width={2706} />

          <Confirm
            label="I’ve created a SAML Enterprise Application"
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 2 && (
      <>
        <Article>
          <H1>Step 2: Basic SAML Configuration</H1>

          <Paragraph>
            Click the Edit icon in the top right of the first step.
          </Paragraph>

          <Img
            priority
            height={366}
            src="/sso/azure/v1/azure-5.png"
            width={1528}
          />

          <CopyInput
            label="Copy this Identifier"
            value={connection?.saml_entity_id}
          />

          <CopyInput
            label="Copy this Reply URL"
            value={connection?.saml_acs_url}
          />

          <Paragraph>
            Submit the Identifier and the Reply URL in the Basic SAML
            Configuration.
          </Paragraph>

          <Img height={1026} src="/sso/azure/v1/azure-6.png" width={1690} />

          <Confirm
            label="I’ve completed my Basic SAML Configuration."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 3 && (
      <>
        <Article>
          <H1>Step 3: User Attributes & Claims</H1>

          <Paragraph>
            Click the Edit icon in the top right of the second step.
          </Paragraph>

          <Img
            priority
            height={366}
            src="/sso/azure/v1/azure-7.png"
            width={1528}
          />

          <Paragraph>
            Fill in the following Attribute Statements and select "Next":
          </Paragraph>

          <ConnectionAttributeMapping connectionType={connection?.type} />

          <Img height={942} src="/sso/azure/v1/azure-8.png" width={1582} />

          <Confirm
            label="I’ve configured the User Attributes & Claims."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 4 && (
      <>
        <Article>
          <H1>Step 4: Assign People & Groups</H1>

          <Paragraph>
            In order for your users and groups of users to be synced to{' '}
            {appName} you will need to assign them to your Azure AD SAML
            Application. Select "Users and groups" from the "Manage" section of
            the navigation menu.
          </Paragraph>

          <Img
            priority
            height={770}
            src="/sso/azure/v1/azure-9.png"
            width={1228}
          />

          <Paragraph>Select "Add user/group" from the top menu.</Paragraph>

          <Img
            priority
            height={710}
            src="/sso/azure/v1/azure-10.png"
            width={1946}
          />

          <Paragraph>
            Select "None selected" under the "Users and Groups". In the menu,
            select the users and groups of users that you want to add to the
            SAML application, and click "Select".
          </Paragraph>

          <Img height={436} src="/sso/azure/v1/azure-11.png" width={3142} />

          <Paragraph>
            Select "Assign" to add the selected users and groups of users to
            your SAML application.
          </Paragraph>

          <Img height={1742} src="/sso/azure/v1/azure-12.png" width={1380} />

          <Confirm
            label="I’ve completed my assignments."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 5 && (
      <>
        <Article>
          <H1>Step 5: SAML Signing Certificate</H1>

          <Paragraph>
            Download the Certificate (Base64) from Step 3 and upload it below.
          </Paragraph>

          <Img
            priority
            height={482}
            src="/sso/azure/v1/azure-13.png"
            width={1524}
          />

          <Card>
            <Form
              disabled={!connection?.saml_x509_certs}
              isUpdate={!!errors?.saml_x509_certs}
              onSubmit={onNextStep}
            >
              <FileField
                error={errors?.saml_x509_certs}
                filename="Certificate (Base64)"
                label="Certificate (Base64)"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Form>
          </Card>
        </Article>
      </>
    )}

    {activeStep === 6 && (
      <>
        <Article>
          <H1>Step 6: Provide a Login URL</H1>

          <Paragraph>
            Copy the Login URL from Step 4 and enter it below.
          </Paragraph>

          <Img
            priority
            height={420}
            src="/sso/azure/v1/azure-14.png"
            width={1516}
          />

          <Card>
            <Form
              disabled={!connection?.saml_idp_url}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              isInline={true}
              isLoading={isLoading}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="Login URL"
                name="saml_idp_url"
                onChange={onInputChange}
                placeholder="https://login.microsoftonline.com/f2416a9f-8064"
                value={connection?.saml_idp_url}
              />
            </Form>
          </Card>
        </Article>
      </>
    )}
  </>
);
