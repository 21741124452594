import { space } from '@workos-inc/ui-kit';
import { ImageModal } from 'components/modals';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';

export type ImageProps = {
  src: string;
  alt?: string;
  style?: CSSProperties;
};

const StyledImage = styled.img`
  cursor: zoom-in;
  display: block;
  margin-top: ${space.spacing(8)};
  margin-bottom: ${space.spacing(8)};
`;

export const Image: React.FC<Readonly<ImageProps>> = ({
  src,
  alt = '',
  style,
}) => {
  const [isZoomed, zoom] = useState<boolean>(false);

  return (
    <>
      <ImageModal
        alt={alt}
        isOpen={isZoomed}
        onClose={() => zoom(false)}
        src={src}
      />
      <StyledImage onClick={() => zoom(true)} src={src} style={style} />
    </>
  );
};
