import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Colors, space } from '@workos-inc/ui-kit';
import React from 'react';
import styled from 'styled-components';
import { RadioContainer } from './radio-container';
import { RadioDescription } from './radio-description';
import { RadioTitle } from './radio-title';

export interface RadioProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
  children: React.ReactNode;
}

export interface Radio {
  Title: typeof RadioTitle;
  Description: typeof RadioDescription;

  Group: typeof RadioGroupPrimitive.Root;
  GroupRadio: typeof StyledRadio;
  GroupIndicator: typeof StyledIndicator;
}

export const Radio: React.FC<Readonly<RadioProps>> & Radio = ({
  isActive,
  children,
}) => <RadioContainer isActive={isActive}>{children}</RadioContainer>;

const StyledRadio = styled(RadioGroupPrimitive.Item)`
  all: unset;

  margin-right: ${space.spacing(2)};

  background-color: white;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  border: 2px solid ${Colors.GrayDarker};
`;

const StyledIndicator = styled(RadioGroupPrimitive.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  

  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Colors.Primary};
  },
`;

Radio.Title = RadioTitle;
Radio.Description = RadioDescription;
Radio.Group = RadioGroupPrimitive.Root;
Radio.GroupRadio = StyledRadio;
Radio.GroupIndicator = StyledIndicator;
