import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const StepJumpCloud: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <>
    {activeStep === 1 && (
      <Article>
        <H1>Step 1: Create SAML Application</H1>

        <Img
          priority
          height={1684}
          src="/sso/jump-cloud/v1/jump-cloud-1.png"
          width={3224}
        />

        <Paragraph>
          From the JumpCloud Admin Console, select “SSO” on the navigation to
          the left. Then select the green “+” sign to create a new application.
        </Paragraph>

        <Img
          priority
          height={1636}
          src="/sso/jump-cloud/v1/jump-cloud-2.png"
          width={2234}
        />

        <Paragraph>
          In the subsequent pop-up window, select “Custom SAML App”.
        </Paragraph>

        <Img
          height={1638}
          src="/sso/jump-cloud/v1/jump-cloud-3-edited.png"
          width={2208}
        />

        <Paragraph>
          Under "Display Label", give it a descriptive name to easily identify
          it later such as {appName}-{connection?.name}.
        </Paragraph>

        <Confirm
          label="I’ve finished naming the SAML Application"
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 2 && (
      <Article>
        <H1>Step 2: Submit ACS URL and Entity ID</H1>

        <Img
          priority
          height={1210}
          src="/sso/jump-cloud/v1/jump-cloud-4-edited.png"
          width={2224}
        />

        <Paragraph>
          Copy the Entity ID into the "IdP Entity ID" and "SP Entity ID" fields.
        </Paragraph>

        <CopyInput
          label="Copy this Entity ID"
          value={connection?.saml_entity_id}
        />

        <Paragraph>Copy the ACS URL into the "ACS URL" field.</Paragraph>

        <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

        <Img
          height={712}
          src="/sso/jump-cloud/v1/jump-cloud-5.png"
          width={1490}
        />

        <Paragraph>
          IMPORTANT: Be sure to check the “Sign Assertion” box.
        </Paragraph>

        <Confirm
          label="I’ve configured the Assertion Consumer Service (ACS) URL and
          Entity ID."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 3 && (
      <Article>
        <H1>Step 3: Configure Attribute Statements</H1>

        <Img
          priority
          height={802}
          src="/sso/jump-cloud/v1/jump-cloud-6.png"
          width={1506}
        />

        <ConnectionAttributeMapping connectionType={connection?.type} />

        <Img
          height={198}
          src="/sso/jump-cloud/v1/jump-cloud-11.png"
          width={531}
        />

        <Paragraph>
          In the “User Attribute Mapping” section, click on the "add attribute"
          button to begin adding new user attributes. Add in the above Attribute
          Statements.
        </Paragraph>

        <Img
          height={125}
          src="/sso/jump-cloud/v1/jump-cloud-7.png"
          width={397}
        />

        <Img
          height={568}
          src="/sso/jump-cloud/v1/jump-cloud-8.png"
          width={1302}
        />

        <Paragraph>
          Once you’ve finished, select “activate” and then click on the
          “continue” button on the subsequent pop-up window.
        </Paragraph>

        <Confirm
          label="I’ve finished configuring the Attribute Statements
          and clicked “activate” and “continue“."
          onClick={onNextStep}
        />
      </Article>
    )}

    {activeStep === 4 && (
      <Article>
        <H1>Step 5: Upload Identity Provider Metadata</H1>

        <Img
          priority
          height={702}
          src="/sso/jump-cloud/v1/jump-cloud-9.png"
          width={1239}
        />

        <Paragraph>Select your newly created Application.</Paragraph>

        <Img
          priority
          height={329}
          src="/sso/jump-cloud/v1/jump-cloud-10.png"
          width={744}
        />

        <Paragraph>
          Download the “IDP Certificate” by clicking on the dropdown menu next
          to “IDP Certificate Valid” and selecting “Download certificate”.
          Upload the X.509 Certificate below when prompted.
        </Paragraph>

        <Paragraph>
          Open the "Single Sign-On Configuration" section and copy the “IDP URL”
          to use as the Identity Provider Single Sign-On URL below.
        </Paragraph>

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              !connection?.saml_entity_id ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={
              !!(
                errors?.saml_idp_url ||
                errors?.saml_entity_id ||
                errors?.saml_x509_certs
              )
            }
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="1. Identity Provider Single Sign-On URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://sso.jumpcloud.com/..."
              value={connection?.saml_idp_url}
            />

            <FileField
              error={errors?.saml_x509_certs}
              filename="X.509 Certificate"
              label="3. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    )}
  </>
);
