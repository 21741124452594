import { Colors, Loading } from '@workos-inc/ui-kit';
import React, { FC, useContext } from 'react';
import styled, { keyframes, ThemeContext } from 'styled-components';

type SpinnerProps = {
  size?: number;
  color?: Colors;
  isCube?: boolean;
};

export const Spinner: FC<Readonly<SpinnerProps>> = ({
  size,
  color,
  isCube,
}) => {
  const theme = useContext(ThemeContext);
  const spinnerColor = color || theme.primary;

  if (isCube) {
    return <SpinnerCube color={spinnerColor} size={size || 40} />;
  }

  return <Loading color={spinnerColor} size={size || 8} />;
};

type SpinnerCubeProps = {
  size?: number;
  color?: Colors;
};

const SpinnerCube: FC<Readonly<SpinnerCubeProps>> = ({ size, color }) => {
  const cubes = [0, 1, 3, 2].map((v) => (
    <Cube key={v} delay={0.3 * v} scale={90 * v} />
  ));

  return (
    <Container>
      <FoldingCube color={color} size={size}>
        {cubes}
      </FoldingCube>
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: ${fadeIn};
  animation-fill-mode: forwards;
  animation-duration: 250ms;
  animation-delay: 1s;
`;

const fold = keyframes`
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`;

export const Cube = styled.div<{
  scale: number;
  delay: number;
}>`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1) rotateZ(${(p) => p.scale}deg);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: ${fold} 2.4s infinite linear both ${(p) => p.delay}s;
    transform-origin: 100% 100%;
  }
`;

export const FoldingCube = styled.div<SpinnerProps>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  position: relative;
  transform: rotateZ(45deg);

  > ${Cube} {
    &::before {
      background-color: ${(p) => p.color};
    }
  }
`;
