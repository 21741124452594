import { Confirm } from 'components/confirm';
import { MapAttributes } from 'components/dsync/map-attributes';
import { Paragraph } from 'components/typography';
import { DirectoryStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const MapAttributesStep: FC<Readonly<DirectoryStepProps>> = ({
  appName,
  onNextStep,
  directory,
  directoryCustomAttributes,
  updateDirectory,
  children,
}) => (
  <>
    {children ? (
      children
    ) : (
      <>
        <Paragraph>
          There are additional attributes needed by the application for it to
          fully function. Note that basic attributes like a user's name or email
          are already mapped, and so are not present below.
        </Paragraph>

        <Paragraph>
          Map attributes from your Directory Provider's responses to the
          attributes shown below.
        </Paragraph>
      </>
    )}

    {directory && directoryCustomAttributes && (
      <MapAttributes
        appName={appName}
        directory={directory}
        directoryCustomAttributes={directoryCustomAttributes}
        onFinishMapping={async () => {
          await updateDirectory(directory);
          onNextStep();
        }}
      >
        {({ disabled, onClick }) => (
          <Confirm
            disabled={disabled}
            label="I’ve finished configuring attribute mappings."
            onClick={onClick}
          />
        )}
      </MapAttributes>
    )}
  </>
);
