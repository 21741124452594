import Cookies from 'js-cookie';

export const ADMIN_PORTAL_API_URL_COOKIE = '_admin_portal_api_url';

export const getCookies = (): {
  apiUrl: string | undefined;
  csrfToken: string | undefined;
} => ({
  apiUrl: Cookies.get(ADMIN_PORTAL_API_URL_COOKIE),
  csrfToken: Cookies.get('_csrf'),
});
