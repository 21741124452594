import React, { FC } from 'react';
import styled from 'styled-components';

export const CenteredPageContent: FC = ({ children }) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

const Container = styled.div`
  margin: 48px auto 0;
  max-width: 857px;
`;

const Content = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;
