import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export type QueryParams = Record<string, string | undefined>;

export function useQueryString(): QueryParams | undefined {
  const [params, setParams] = useState<QueryParams>();
  const router = useRouter();

  useEffect(() => {
    if (router.isReady && !params) {
      setParams(
        Object.entries(router.query).reduce(
          (obj, [key, value]) => ({
            ...obj,
            [key]: value instanceof Array ? value[0] : value,
          }),
          {},
        ),
      );

      // remove the query string once we parse it
      void router.replace(window.location.href, window.location.pathname);
    }
  }, [router, params]);

  return params;
}
