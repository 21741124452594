import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { TextField } from 'components/fields';
import { Form } from 'components/form';
import { CopyInput } from 'components/input';
import { Article, H1, LI, Paragraph, UL } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, useState } from 'react';
import { graphql } from 'utils/graphql';

export const StepOpenID: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => {
  const [identityProvider, setIdentityProvider] = useState('');

  const handleIdPSubmit = (): void => {
    void graphql().RequestIdentityProvider({
      identityProvider,
    });
    onNextStep();
  };

  return (
    <>
      {activeStep === 1 && (
        <Article>
          <H1>Step 1: Provide IdP Information</H1>
          <Card>
            <Form
              disabled={!identityProvider}
              isInline={true}
              onSubmit={handleIdPSubmit}
            >
              <TextField
                autoFocus={true}
                label="Which Identity Provider are you connecting to?"
                name="identity_provider"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setIdentityProvider(event.target.value)
                }
                placeholder="e.g. Okta, Azure AD, OneLogin..."
                value={identityProvider}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 2 && (
        <Article>
          <H1>Step 2: Create an Application with your IdP</H1>

          <Paragraph>
            For SSO to properly function with your Identity Provider, you'll
            need to create and configure your application to:
          </Paragraph>

          <UL>
            <LI>Support the authorization code grant type</LI>
            <LI>Have the URL below listed as a login redirect URI</LI>
          </UL>

          <CopyInput
            label="Copy your login redirect URI"
            value={connection?.oidc_redirect_uri}
          />

          <Confirm
            label="I’ve created and configured my application"
            onClick={onNextStep}
          />
        </Article>
      )}

      {activeStep === 3 && (
        <Article>
          <H1>Step 3: Provide your Client Credentials</H1>

          <Paragraph>
            After creating an application in the last step, a Client ID and
            Client Secret would've been provisioned for you by your Identity
            Provider. These credentials are used to authenticate users from{' '}
            {appName}.
          </Paragraph>

          <Card>
            <Form
              disabled={
                !connection?.oidc_client_id || !connection?.oidc_client_secret
              }
              isLoading={isLoading}
              isUpdate={
                !!(errors?.oidc_client_id || errors?.oidc_client_secret)
              }
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                error={errors?.oidc_client_id}
                label="Client ID"
                name="oidc_client_id"
                onChange={onInputChange}
                value={connection?.oidc_client_id}
              />

              <TextField
                error={errors?.oidc_client_secret}
                label="Client Secret"
                name="oidc_client_secret"
                onChange={onInputChange}
                value={connection?.oidc_client_secret}
              />
            </Form>
          </Card>
        </Article>
      )}

      {activeStep === 4 && (
        <Article>
          <H1>Step 4: Provide your Discovery Endpoint</H1>

          <Paragraph>
            Your Identity Provider's Discovery Endpoint contains important
            configuration information.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.oidc_discovery_endpoint}
              error={
                errors?.oidc_discovery_endpoint ||
                validationErrors?.oidc_discovery_endpoint
              }
              isInline={true}
              isLoading={isLoading}
              isUpdate={!!errors?.oidc_discovery_endpoint}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="Discovery Endpoint"
                name="oidc_discovery_endpoint"
                onChange={onInputChange}
                placeholder="https://your-domain.com/.well-known/openid-configuration"
                value={connection?.oidc_discovery_endpoint}
              />
            </Form>
          </Card>
        </Article>
      )}
    </>
  );
};
