import {
  ButtonAppearance,
  IconEye,
  IconEyeOff,
  Row,
  space,
} from '@workos-inc/ui-kit';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { Value } from 'components/input/value';
import { Label } from 'components/label';
import { Spinner } from 'components/spinner';
import copy from 'copy-text-to-clipboard';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';

type CopyInputProps = {
  label: string;
  onCopy?: () => void;
  style?: CSSProperties;
  value?: string | null;
  type?: 'text' | 'password';
};

const ValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CopyInput: React.FC<Readonly<CopyInputProps>> = ({
  label,
  onCopy,
  value,
  style,
  type,
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  const handleCopy = (): void => {
    setTimeout(() => setCopied(false), 1000);

    if (!value) {
      return;
    }

    setCopied(true);

    copy(value);

    if (onCopy) {
      onCopy();
    }
  };

  const valueToDisplay =
    value && type === 'password' && !isPasswordVisible
      ? value.replace(/./g, '•')
      : value;

  const VisibilityToggle = () => {
    if (type !== 'password') {
      return null;
    }

    const VisibilityIcon = isPasswordVisible ? IconEyeOff : IconEye;
    return (
      <VisibilityIcon onClick={() => setPasswordVisible(!isPasswordVisible)} />
    );
  };

  return (
    <Card style={style}>
      <Label>{label}</Label>
      <Row marginTop={space.spacing(2)}>
        <Value>
          {value ? (
            <ValueContainer>
              {valueToDisplay}
              <VisibilityToggle />
            </ValueContainer>
          ) : (
            <Spinner />
          )}
        </Value>
        <Button
          appearance={copied ? ButtonAppearance.Green : undefined}
          disabled={!value}
          onClick={handleCopy}
          style={{
            marginLeft: space.spacing(2),
            height: 36,
          }}
        >
          {copied ? 'Copied!' : 'Click to copy'}
        </Button>
      </Row>
    </Card>
  );
};
