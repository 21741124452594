import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const StepOneLogin: React.FC<Readonly<ConnectionStepProps>> = ({
  activeStep,
  appName,
  connection,
  errors,
  onFileInput,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <>
    {activeStep === 1 && (
      <>
        <Article>
          <H1>Step 1: Create a SAML Application</H1>

          <Paragraph>
            Click “Add App” in the top right corner of your application
            dashboard.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-1.png"
            width={1278}
          />

          <Paragraph>
            Click “SAML Custom Connector (Advanced)” and continue.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-15.png"
            width={1278}
          />

          <Paragraph>
            Enter a Display Name that describes {appName}, then select “Save”.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-16.png"
            width={1278}
          />

          <Confirm
            label="I’ve created a SAML Enterprise Application"
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 2 && (
      <>
        <Article>
          <H1>Step 2: Basic SAML Configuration</H1>

          <Paragraph>
            Click the “Configuration” tab from the left sidebar.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-4.png"
            width={1278}
          />

          <CopyInput
            label="Copy this ACS URL Validator"
            value={
              connection?.saml_acs_url
                ? `^${connection?.saml_acs_url.replace(/\//g, '\\/')}$`
                : undefined
            }
          />

          <Paragraph>
            Submit the “ACS URL Validator” in the Application details.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-5.png"
            width={1278}
          />

          <CopyInput
            label="Copy this ACS URL"
            value={connection?.saml_acs_url}
          />

          <Paragraph>
            Submit the “ACS URL” in the Application details.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-6.png"
            width={1278}
          />

          <Paragraph>
            Enter the Login URL that your SP uses to initiate the login in the
            Login URL field.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-14.png"
            width={975}
          />

          <Confirm
            label="I’ve completed my Basic SAML Configuration."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 3 && (
      <>
        <Article>
          <H1>Step 3: Advanced SAML Configuration</H1>

          <Paragraph>
            Continue scrolling down the Application Details to “SAML Initiator”.
          </Paragraph>

          <Paragraph>
            Select “Service Provider” under “SAML Initiator”.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-7.png"
            width={1278}
          />

          <Paragraph>
            Select “Assertion” under “SAML signature element”.
          </Paragraph>

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-8.png"
            width={1278}
          />

          <Confirm
            label="I’ve selected “Assertion” as the signature element."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 4 && (
      <>
        <Article>
          <H1>Step 4: Configure Parameters</H1>

          <Paragraph>
            Click the “Parameters” tab from the left sidebar.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-9.png"
            width={1278}
          />

          <Paragraph>
            {`Fill in the following parameters and check the "Include in SAML
            assertion" flag for each pair.`}
          </Paragraph>

          <ConnectionAttributeMapping connectionType={connection?.type} />

          <Img
            height={740}
            src="/sso/one-login/v1/one-login-10.png"
            width={1278}
          />

          <Confirm
            label="I’ve configured the Application’s parameters."
            onClick={onNextStep}
          />
        </Article>
      </>
    )}

    {activeStep === 5 && (
      <>
        <Article>
          <H1>Step 5: Upload X.509 Certificate</H1>

          <Paragraph>Click the “SSO” tab from the left sidebar.</Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-11.png"
            width={1278}
          />

          <Paragraph>
            Click “View Details” under the X.509 Certificate.
          </Paragraph>

          <Paragraph>
            Clicking will open a certificate configuration page.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-12.png"
            width={1278}
          />

          <Paragraph>
            Download the X.509 Certificate and upload it below.
          </Paragraph>

          <Card>
            <Form
              disabled={!connection?.saml_x509_certs}
              isUpdate={!!errors?.saml_x509_certs}
              onSubmit={onNextStep}
            >
              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Form>
          </Card>
        </Article>
      </>
    )}

    {activeStep === 6 && (
      <>
        <Article>
          <H1>Step 6: Provide SAML 2.0 Endpoint</H1>

          <Paragraph>
            Navigate back to the “SSO” tab in your Application and copy the SAML
            2.0 Endpoint.
          </Paragraph>

          <Img
            priority
            height={740}
            src="/sso/one-login/v1/one-login-13.png"
            width={1278}
          />

          <Card>
            <Form
              disabled={!connection?.saml_idp_url}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              isLoading={isLoading}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                label="SAML 2.0 Endpoint"
                name="saml_idp_url"
                onChange={onInputChange}
                placeholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
                value={connection?.saml_idp_url}
              />
            </Form>
          </Card>
        </Article>
      </>
    )}
  </>
);
