import { Box, Colors, space } from '@workos-inc/ui-kit';
import styled from 'styled-components';

export const Paragraph = styled(Box).attrs({ as: 'p' })`
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.TonedMidGray};
  margin-top: ${space.spacing(8)};
  margin-bottom: ${space.spacing(8)};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
