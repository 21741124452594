import jwt from 'jsonwebtoken';
import { graphql } from 'utils/graphql';
import { QueryParams } from './use-query-string';

const isDate = (date: string): boolean =>
  new Date(date).toString() !== 'Invalid Date' &&
  !isNaN(new Date(date).getTime());

export const getTelemetryData = (
  queryParams: QueryParams,
): { started_at: number; legacy: boolean } | void => {
  const legacy = !Boolean(queryParams.token);
  let started_at;

  if (queryParams.started_at) {
    started_at = Number(queryParams.started_at);
  } else if (queryParams.token) {
    try {
      const token = jwt.decode(queryParams.token);

      if (
        typeof token === 'object' &&
        token?.started_at &&
        isDate(token?.started_at)
      ) {
        started_at = Number(token.started_at);
      }
    } catch (error) {
      return;
    }
  }

  if (started_at) {
    return { legacy, started_at };
  }
};

export const getTelemetryRequests = (
  queryParams: QueryParams,
): [VoidFunction, VoidFunction] => {
  const telemetryData = getTelemetryData(queryParams);

  if (telemetryData) {
    void graphql().Ready(telemetryData);
  }

  const loaded = () => {
    if (telemetryData) {
      void graphql().Loaded(telemetryData);
    }
  };

  const ready = () => {
    if (telemetryData) {
      void graphql().Ready(telemetryData);
    }
  };

  return [loaded, ready];
};
