import { ButtonAppearance, Colors } from '@workos-inc/ui-kit';
import React from 'react';
import styled from 'styled-components';
import {
  FlashMessage,
  FlashMessageBox,
  FlashMessageProps,
} from './flash-message';

export const SuccessFlashMessage: React.FC<Readonly<FlashMessageProps>> = (
  props,
) => (
  <FlashMessage
    BoxComponent={SuccessFlashMessageBox}
    buttonAppearance={ButtonAppearance.Green}
    {...props}
  />
);

const SuccessFlashMessageBox = styled(FlashMessageBox)`
  background-color: #d7f0e2;
  border: 1px solid ${Colors.Green};
`;
