import { Colors, Radius, space } from '@workos-inc/ui-kit';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Text = styled.span`
  font-size: 15px;
  color: ${Colors.TonedMidGray};
`;

const Container = styled.div`
  width: 100%;
  border-radius: ${Radius.Medium};
  background-color: ${Colors.TonedLighterGray};
  padding: ${space.spacing(2)} ${space.spacing(3)};
`;

interface ValueProps {
  children: ReactNode;
}

export const Value: React.FC<Readonly<ValueProps>> = ({ children }) => (
  <Container>
    <Text>{children}</Text>
  </Container>
);
