import is from '@sindresorhus/is';
import router from 'next/router';
import { logError } from './logger';

export const handleError = (error: unknown): void => {
  logError(error);

  if (is.error(error) && error.message === 'Invalid token') {
    return void router.push('/expired');
  }

  return void router.push('/not_found');
};
