import { Colors, Radius, Row, space } from '@workos-inc/ui-kit';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { Label } from 'components/label';
import { Anchor, LI, Paragraph, UL } from 'components/typography';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import styled from 'styled-components';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsGoogleWorkspace: ArrayStepsConfig = {
  providerLabel: 'Google Workspace',
  type: 'Array',
  steps: [
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Paragraph>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Paragraph>

          <Paragraph>
            The full list of available attributes from Google Workspace can be
            found{' '}
            <Anchor
              href={
                'https://developers.google.com/admin-sdk/directory/reference/rest/v1/users'
              }
              target="_blank"
            >
              here.
            </Anchor>
          </Paragraph>

          <Paragraph>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Paragraph>
        </MapAttributesStep>
      ),
    },
    {
      name: 'Allow Access to Google Workspace',
      // eslint-disable-next-line react/display-name
      render: ({
        appName,
        directory,
        isLoading,
        domain,
      }: DirectoryStepProps) => (
        <>
          <Paragraph>
            To connect your Google Workspace directory to {appName} you will
            need to allow access to:
          </Paragraph>

          <UL>
            <LI>View your {domain} email address</LI>
            <LI>View groups on your domain</LI>
            <LI>View users on your domain</LI>
          </UL>

          <Paragraph>
            Click <strong>Allow Access</strong> to continue.
          </Paragraph>

          <Card>
            <Label>Your Google Workspace Domain</Label>
            <DomainField>{domain}</DomainField>
            <Row justifyContent="flex-end">
              <Button
                disabled={!directory}
                isLoading={isLoading}
                onClick={() =>
                  (window.location.href = directory?.setup_url || '')
                }
                style={{ alignSelf: 'flex-end' }}
              >
                Allow Access
              </Button>
            </Row>
          </Card>
        </>
      ),
    },
  ],
};

const DomainField = styled.div`
  width: 100%;
  border-radius: ${Radius.Medium};
  background-color: ${Colors.TonedLighterGray};
  padding: ${space.spacing(2)} ${space.spacing(3)};
  margin-bottom: ${space.spacing(4)};
`;
