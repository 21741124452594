import { Colors, Row, space } from '@workos-inc/ui-kit';
import { Button } from 'components/button';
import { Card } from 'components/card';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const Label = styled.span`
  font-size: 17px;
  color: ${Colors.TonedMidGray};
  margin-right: ${space.spacing(6)};
`;

interface ConfirmProps {
  buttonText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
  style?: CSSProperties;
  submit?: boolean;
}

export const Confirm: React.FC<Readonly<ConfirmProps>> = ({
  buttonText = 'Continue to Next Step',
  disabled,
  isLoading,
  label,
  onClick,
  style,
}) => (
  <Card style={style}>
    <Row alignItems="center" justifyContent="space-between">
      <Label>{label}</Label>
      <Button
        disabled={disabled}
        isLoading={isLoading}
        onClick={onClick}
        style={{ marginLeft: space.spacing(2) }}
      >
        {buttonText}
      </Button>
    </Row>
  </Card>
);
