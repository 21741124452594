import {
  Button,
  ButtonAppearance,
  Colors,
  Radius,
  Row,
  space,
  Weight,
} from '@workos-inc/ui-kit';
import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';

export type FlashMessageProps = {
  BoxComponent?: AnyStyledComponent;
  buttonAppearance?: ButtonAppearance;
  buttonText?: string;
  message?: string;
  onButtonClick?(): void;
  title?: string;
};

export const FlashMessage: React.FC<Readonly<FlashMessageProps>> = ({
  BoxComponent = FlashMessageBox,
  buttonAppearance = ButtonAppearance.BrandPrimary,
  buttonText = 'View Details',
  message,
  onButtonClick,
  title,
}) => (
  <BoxComponent data-testid="flash-message">
    <div>
      {title && <FlashMessageTitle>{title}</FlashMessageTitle>}
      {message}
    </div>

    {onButtonClick && (
      <FlashMessageButton appearance={buttonAppearance} onClick={onButtonClick}>
        {buttonText}
      </FlashMessageButton>
    )}
  </BoxComponent>
);

export const FlashMessageBox = styled(Row)`
  align-items: center;
  background-color: #f8f9fc;
  border: 1px solid ${Colors.TonedLightGray};
  border-radius: ${Radius.Medium};
  color: ${Colors.TonedDarkGray};
  font-size: 13px;
  justify-content: space-between;
  margin-bottom: ${space.spacing(6)};
  padding: ${space.spacing(3)} ${space.spacing(4)};
  width: 100%;
`;

const FlashMessageButton = styled(Button)`
  height: fit-content;
  padding: ${space.spacing(2)} ${space.spacing(5)};
`;

const FlashMessageTitle = styled.h4`
  font-weight: ${Weight.SemiBold};
  margin-bottom: ${space.spacing(2)};
`;
