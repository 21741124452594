import { Modal } from '@workos-inc/ui-kit';
import React from 'react';
import styled from 'styled-components';
import { useKeys } from 'utils/use-keys';

interface ImageModalProps {
  isOpen: boolean;
  onClose(): void;
  src: string;
  alt: string;
}

export const ImageModal: React.FC<Readonly<ImageModalProps>> = ({
  isOpen,
  onClose,
  src,
  alt,
}) => {
  useKeys(['Escape', 'Esc'], onClose);

  return (
    <Modal isOpen={isOpen} maxWidth="1000px" onClose={onClose} title={alt}>
      <ModalImage alt={alt} src={src} />
    </Modal>
  );
};

const ModalImage = styled('img')`
  display: block;
  height: auto;
  margin: 0 auto;
  max-height: calc(100vh - 150px);
  max-width: 100%;
  width: auto;
`;
