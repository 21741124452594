import { ButtonAppearance, Colors } from '@workos-inc/ui-kit';
import React from 'react';
import styled from 'styled-components';
import {
  FlashMessage,
  FlashMessageBox,
  FlashMessageProps,
} from './flash-message';

export const ErrorFlashMessage: React.FC<Readonly<FlashMessageProps>> = (
  props,
) => (
  <FlashMessage
    BoxComponent={ErrorFlashMessageBox}
    buttonAppearance={ButtonAppearance.Red}
    {...props}
  />
);

const ErrorFlashMessageBox = styled(FlashMessageBox)`
  background-color: #f7e1e1;
  border: 1px solid ${Colors.Red};
`;
