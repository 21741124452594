import { Colors, space, Weight } from '@workos-inc/ui-kit';
import styled from 'styled-components';

export const LI = styled.li`
  color: ${Colors.TonedMidGray};
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: ${space.spacing(3)};

  strong {
    font-weight: ${Weight.Medium};
  }
`;

export const OL = styled.ol`
  margin-top: ${space.spacing(4)};
  margin-left: ${space.spacing(5)};
`;

export const UL = styled.ul`
  margin-top: ${space.spacing(4)};
  margin-left: ${space.spacing(5)};
`;
