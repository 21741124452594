import { InputType } from '@workos-inc/ui-kit/dist/interfaces/input';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { Anchor, Paragraph } from 'components/typography';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsBambooHR: ArrayStepsConfig = {
  providerLabel: 'BambooHR',
  type: 'Array',
  steps: [
    {
      name: 'Generate API Key in BambooHR',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep }: DirectoryStepProps) => (
        <>
          <Paragraph>
            Log in to your BambooHR instance as an admin user and select your
            name in the upper right-hand corner of the BambooHR console, then
            select “API keys” in the menu.
          </Paragraph>

          <Img
            priority
            height={356}
            src="/directory-sync/bamboo-hr/v1/bamboo-hr-1.png"
            width={412}
          />

          <Paragraph>Select "Add New Key".</Paragraph>

          <Img
            priority
            height={281}
            src="/directory-sync/bamboo-hr/v1/bamboo-hr-2.png"
            width={1385}
          />

          <Paragraph>
            Give your key a descriptive name such as <strong>{appName}</strong>{' '}
            and select "Generate Key".
          </Paragraph>

          <Img
            height={322}
            src="/directory-sync/bamboo-hr/v1/bamboo-hr-3.png"
            width={632}
          />

          <Paragraph>
            Select "Copy Key" and save this API key, which you'll upload in the
            next step.
          </Paragraph>

          <Img
            height={406}
            src="/directory-sync/bamboo-hr/v1/bamboo-hr-4.png"
            width={628}
          />

          <Confirm
            label="I've generated my BambooHR API key."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload BambooHR API Key and Subdomain',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange,
        onNextStep,
      }: DirectoryStepProps) => (
        <>
          <Paragraph>
            Upload the API key you generated in your last step, along with your
            company's BambooHR subdomain. If you access BambooHR at
            https://mycompany.bamboohr.com, then the sudomain is "mycompany".
          </Paragraph>

          <Card>
            <Form
              disabled={
                !directory?.bamboo_hr_api_key || !directory?.bamboo_hr_subdomain
              }
              isLoading={isLoading}
              isUpdate={
                !!(errors?.bamboo_hr_api_key || errors?.bamboo_hr_subdomain)
              }
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                error={errors?.bamboo_hr_api_key}
                label="1. BambooHR API Key"
                name="bamboo_hr_api_key"
                onChange={onInputChange}
                placeholder=""
                type={InputType.Password}
                value={directory?.bamboo_hr_api_key}
              />

              <TextField
                error={errors?.bamboo_hr_subdomain}
                label="2. Your Company's BambooHR Subdomain"
                name="bamboo_hr_subdomain"
                onChange={onInputChange}
                placeholder="mycompany"
                value={directory?.bamboo_hr_subdomain}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <>
          <Paragraph>
            There are additional attributes needed by the application for it to
            fully function.
          </Paragraph>

          <Paragraph>
            BambooHR by default will provide the following additional attributes
            that are available for mapping: "id", "status", "lastName",
            "firstName", "homeEmail", "workEmail", "department", and
            "displayName". However, additional attributes can be added to the
            "raw_attributes" by request.
          </Paragraph>

          <Img
            priority
            height={1200}
            src="/directory-sync/bamboo-hr/v1/bamboo-hr-5.png"
            width={1450}
          />

          <Paragraph>
            The full list of available attributes from BambooHR can be found{' '}
            <Anchor
              href={
                'https://documentation.bamboohr.com/docs/list-of-field-names'
              }
              target="_blank"
            >
              here
            </Anchor>
            . If you need attributes other than the ones found on the default
            list, reach out to us at{' '}
            <Anchor href="mailto: support@workos.com" target="_blank">
              support@workos.com
            </Anchor>{' '}
            and we can add them on your behalf.
          </Paragraph>

          <Paragraph>
            For example, if you wanted to use the value of "workEmail", you
            would enter "workEmail" in the Directory Provider Value field.
          </Paragraph>

          <MapAttributesStep {...directoryStepProps} />
        </>
      ),
    },
  ],
};
