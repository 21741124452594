import { Colors, H1 as UIH1, H2 as UIH2, Weight } from '@workos-inc/ui-kit';
import styled from 'styled-components';

export const H1 = styled(UIH1)`
  color: ${Colors.TonedDarkGray};
  font-weight: ${Weight.Medium};
  font-size: 28px;
`;

export const H2 = styled(UIH2)`
  font-weight: ${Weight.Medium};
  color: ${Colors.TonedDarkGray};
  font-size: 19px;
`;
