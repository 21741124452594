import { Box, space, Weight } from '@workos-inc/ui-kit';
import styled from 'styled-components';

export const Label = styled(Box).attrs({ as: 'label' })`
  display: block;
  font-size: 15px;
  font-weight: ${Weight.Medium};
  color: ${(props) => props.color || props.theme.primary};
  margin-bottom: ${space.spacing(2)};
`;
